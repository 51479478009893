import React, { useRef, useImperativeHandle, useMemo } from 'react';
import Tables from '../../../components/Tables';
import { Checkbox } from '../../../components';
import _ from 'lodash';

const formTableHeader = [
    {
        labelKey: '',
        label: ({ onChange, checkboxRef, defaultChecked }) => (
            <Checkbox onChange={onChange} inputRef={checkboxRef} defaultChecked={defaultChecked} />
        ),
        minWidth: 50,
        align: 'center',
        format: (value, selfData, sourceParams) => {
            const { checkboxRef, onChange } = sourceParams;
            return <Checkbox inputRef={checkboxRef} onChange={onChange} defaultChecked={selfData['isChecked']} />;
        },
    },
    {
        labelKey: 'memberNo',
        label: ({ counts }) => {

            return `會員編號(${counts})`;
        },
        minWidth: 120,
        align: 'left',
    },
    {
        labelKey: 'memberInfo',
        label: '會員資訊',
        minWidth: 140,
        format: (value, selfData) => {
            const Tag = (
                <React.Fragment>
                    <div>{selfData['memberName']}</div>
                    <div>{selfData['cellPhone']}</div>
                </React.Fragment>
            );
            return Tag;
        },
    },
    {
        labelKey: 'ownedShop',
        label: '所屬店舖',
        minWidth: 140,
    },
];

const MenberTransferTable = ({ defaultData = [] }, ref) => {
    defaultData = defaultData.map((target) => ({
        ...target,
        isChecked: true,
    }));
    const headerLabelRef = useRef();
    const rowsCheckedRef = useRef([]);
    const isAllChecked = useMemo(() => {
        // 刷新component的ref array會一直暫存
        rowsCheckedRef.current = [];
        return !_.isEmpty(defaultData) && _.every(defaultData, 'isChecked');
    }, [defaultData]);

    const handleHeaderCheckboxOnChange = (e, value) => {
        if (e.nativeEvent.pointerType === 'mouse') {
            _.forEach(rowsCheckedRef.current, (target) => {
                if (value) !target.checked && target.click();
                else target.checked && target.click();
            });
        }
    };

    const handleCheckboxOnChange = (e, value) => {
        if (e.nativeEvent.pointerType === 'mouse') {
            const isHeaderChecked = _.every(rowsCheckedRef.current, 'checked');
            if (isHeaderChecked) headerLabelRef.current.click();
            else headerLabelRef.current.checked && headerLabelRef.current.click();
        }
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const sourceData = _.filter(defaultData, (target, index) => rowsCheckedRef.current[index].checked);
            return sourceData.map(({ memberInfoID }) => memberInfoID);
        },
    }));
    return (
        <React.Fragment>
            <Tables maxHeight={580}>
                <Tables.TableRowHeader
                    headerLabel={formTableHeader}
                    onChange={handleHeaderCheckboxOnChange}
                    checkboxRef={headerLabelRef}
                    defaultChecked={isAllChecked}
                    counts={defaultData?.length ?? 0}
                />
                <Tables.TableRowBody
                    headerLabel={formTableHeader}
                    sourceData={defaultData}
                    onChange={handleCheckboxOnChange}
                    checkboxRef={rowsCheckedRef}
                />
            </Tables>
        </React.Fragment>
    );
};

export default React.forwardRef(MenberTransferTable);
