import React, { useRef } from 'react';
// @material-ui
import { Card, Button, DatePicker } from '../../../components';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';

const RecoveryCard = ({ cycleOrderID, sourceData, setPresetOrderStatus }) => {
    const history = useHistory();
    const recoveryDateRef = useRef();
    const recoveryTime = sourceData.recoveryTime ? moment(sourceData.recoveryTime).format('YYYY/MM') : null;
    const getResult = () => {
        const recoveryDate = recoveryDateRef.current.value;
        return {
            recoveryTime: recoveryDate,
        };
    };

    const doApi = async (params) => {
        await Axios.put(`/api/cycleorders/recovery/${cycleOrderID}`, { ...params }).then((res) => {
            const { data } = res;
            if (data.success) {
                alert(data.message);
                history.push('/order/cycleorderlist');
            }
        });
    };

    const handleOnClick = (e) => {
        doApi(getResult());
    };

    const handleCancel = (e) => {
        setPresetOrderStatus('');
    };

    return (
        <div className="view-normal-width container-root container-disable-gutters">
            <React.Fragment>
                <Card className="title-color-root title-color-pseudo-auxiliary-1-bg child-X-Gutters-2">
                    <div className="title-color-label">訂單恢復</div>
                    <div>
                        <div className="form-flex-row">
                            <DatePicker label="訂單恢復日" inputRef={recoveryDateRef} defaultValue={recoveryTime} disablePast format="yyyy/MM" />
                        </div>
                    </div>
                </Card>
                <div className="flex flex-jc-center child-Y-Gutters-3 button-root">
                    <Button label={`取消`} handleOnClick={handleCancel} color="auxiliary_a" />
                    <Button label={`送出`} fullColor handleOnClick={handleOnClick} color="auxiliary_a" />
                </div>
            </React.Fragment>
        </div>
    );
};

export default RecoveryCard;
