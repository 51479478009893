import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import routes from '../../home/routes';
import _ from 'lodash';

const TopBar = () => {
    const location = useLocation();
    const [headerInfo, setHeaderInfo] = useState({});

    const breadCrumbFn = useCallback((source, pathname, num ,sourceArray = []) => {
        let idx = num + 1;
        let currentIndex = pathname.split('/');
        _.forEach(source, ({ href, text, children }) => {
            if (href.match(currentIndex[idx])) {
                sourceArray.push(text);
                if (children) breadCrumbFn(children, pathname, idx,sourceArray);
                return false;
            }
            if(href.includes(':')) sourceArray.push(text);
        });

        return sourceArray;
    }, []);

    const matchPathname = useCallback(
        (source, pathname) => {
            let title = '';
            let breadcrumbs = [];
            let currentIndex;
            _.forEach(source, ({ href, text }, index) => {
                if (pathname.match(href)) {
                    title = text;
                    currentIndex = index;
                    return false;
                }
            });

            if (source[currentIndex]) breadcrumbs = breadCrumbFn(source[currentIndex].children, pathname, 1);
            setHeaderInfo({ title, breadcrumbs });
        },
        [breadCrumbFn]
    );

    useEffect(() => {
        matchPathname(routes.children, location.pathname);
    }, [location.pathname, matchPathname]);

    return (
        <React.Fragment>
            <h3 className="ban-select">{headerInfo.title || ''}</h3>
            <div className="bread-crumb-root">
                {_.isArray(headerInfo.breadcrumbs) &&
                    _.map(headerInfo.breadcrumbs, (target) => (
                        <span className="ban-select" key={target}>
                            {target}
                        </span>
                    ))}
            </div>
        </React.Fragment>
    );
};

export default TopBar;
