import React from 'react';
import DialogModal from '../../components/dialog/DialogModal';
import { ErrorOutline } from '@material-ui/icons';
import { Button } from '../../components';

const CategoriesDeleteDialog = ({ options, categoryId }) => {
    console.log(categoryId);
    return (
        <React.Fragment>
            <DialogModal.Body>
                <div className="category-dialog-delete-content">
                    <div className="category-dialog-warn">
                        <ErrorOutline className="category-dialog-warn-icon" />
                    </div>
                    <div className="category-dialog-title">請先確認您要進行的動作</div>
                    <div className="category-dialog-context">
                        確認要將&nbsp;<strong>{options[categoryId].name}</strong>&nbsp;刪除？
                    </div>
                    <div className="category-dialog-subtitle">這個動作將無法復原</div>
                </div>
            </DialogModal.Body>
            <DialogModal.Footer>
                <div className="category-dialog-button">
                    <Button color="auxiliary_b" fullColor>
                        刪除
                    </Button>
                    <Button color="auxiliary_b">取消</Button>
                </div>
            </DialogModal.Footer>
        </React.Fragment>
    );
};

export default CategoriesDeleteDialog;
