import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/dispatch';

import { Card } from '../components';
import ProductTable from './Components/ProductTable';
import ProductSearchBox from './Components/ProductSearchBox';

import useAxiosFunction from '../apis/useAxiosFn';
import moment from 'moment';

// import { withProductListProvider } from './_productList.mock';
// withProductListProvider(); // api

const ProductList = ({ actions }) => {
    const [defaultData, setDefaultData] = useState([]);
    const [isMount, setIsMount] = useState(false);
    const searchBoxRef = useRef();
    const Ajax = useCallback(useAxiosFunction(actions), []);

    const handleChangePage = useCallback(
        async (e, num, searchString) => {
            if (searchString === undefined) searchString = searchBoxRef.current?.getResult() ?? '';
            const apiData = await Ajax.post(
                '/api/products',
                {
                    name: searchString,
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD'),
                    pageIndex: num,
                    pageSize: 20,
                },
                {},
                {
                    isQueryString: true,
                }
            );
            if (apiData) setDefaultData({ ...apiData });
        },
        [Ajax]
    );

    const menoProductSearchBox = useMemo(
        () => <ProductSearchBox ref={searchBoxRef} handleChangePage={handleChangePage} />,
        [handleChangePage]
    );

    const initFetchAllData = useCallback(async () => {
        const apiData = await Ajax.post('/api/products');
        if (apiData) {
            setDefaultData({ ...apiData });
            setIsMount(true);
        }
    }, [Ajax]);

    useEffect(() => {
        initFetchAllData();
        return () => {
            setIsMount(false);
        };
    }, [initFetchAllData]);

    return (
        <React.Fragment>
            <Card noWidth>{menoProductSearchBox}</Card>
            <Card noWidth>
                {isMount && <ProductTable defaultData={defaultData} handleChangePage={handleChangePage} />}
            </Card>
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(ProductList);
