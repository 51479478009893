import CycleOrderList from '../order/cycle/CycleOrderList';
import CycleOrderEdit from '../order/cycle/CycleOrderEdit';
import CycleOrderExport from '../order/download/CycleOrderExport';
import MemberTransferList from '../member/transfer/MemberTransferList';
import ProductCategoryList from '../categories/ProductCategoryList';
import ProductBrand from '../brands/ProductBrand';
import ProductList from '../products/ProductList';
import ProductEdit from '../products/ProductEdit';
import ProductStock from '../stock/ProductStock';
import ClassIcon from '@material-ui/icons/Class';

const routes = {
    id: '/',
    href: '',
    component: CycleOrderList,
    exact: true,
    children: [
        {
            id: '/order',
            href: '/order',
            text: '訂單管理',
            className: 'law-document',
            children: [
                {
                    id: '/cycleorderlist',
                    text: '循環訂單中心',
                    href: '/cycleorderlist',
                    component: CycleOrderList,
                    strict: true,
                },
                {
                    id: '/cycleorderedit',
                    text: '循環訂單中心',
                    href: '/cycleorderedit',
                    hidden: true,
                    children: [
                        {
                            id: '/add',
                            text: '循環訂單新增',
                            href: '/add',
                            component: CycleOrderEdit,
                            strict: true,
                            hidden: true,
                        },
                        {
                            id: '/:ocid',
                            text: '循環訂單編輯',
                            href: '/:ocid',
                            component: CycleOrderEdit,
                            strict: true,
                            hidden: true,
                        },
                    ],
                },
                {
                    id: '/cycleorderexport',
                    text: '循環訂單下載',
                    href: '/cycleorderexport',
                    component: CycleOrderExport,
                    strict: true,
                },
            ],
        },
        {
            id: '/member',
            href: '/member',
            text: '會籍管理',
            className: 'user-female',
            children: [
                {
                    id: '/transfer',
                    text: '會籍轉移',
                    href: '/transfer',
                    component: MemberTransferList,
                    strict: true,
                },
            ],
        },
        {
            id: '/products-brands',
            href: '/brands',
            text: '品牌商管理',
            className: 'certificate',
            component: ProductBrand,
            strict: true,
        },
        {
            id: '/products-categories',
            href: '/categories',
            text: '商品分類管理',
            iconDom: ClassIcon,
            component: ProductCategoryList,
            strict: true,
        },
        {
            id: '/products',
            href: '/products',
            text: '商品管理',
            className: 'box',
            component: ProductList,
            strict: true,
            children: [
                {
                    id: '/products-add',
                    href: '/add',
                    text: '新增商品',
                    component: ProductEdit,
                    strict: true,
                    hidden: true,
                },
                {
                    id: '/products-edit',
                    href: '/:pid',
                    text: '編輯商品',
                    component: ProductEdit,
                    strict: true,
                    hidden: true,
                },
            ],
        },
        {
            id: '/stock',
            href: '/stock',
            text: '庫存',
            className: 'cubes',
            component: ProductStock,
            strict: true,
        },
    ],
};

export default routes;
