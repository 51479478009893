import React, { useCallback } from 'react';
import { Button } from '../../../components';
import useCommomFn from '../../../common/useCommomFn';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import useAxiosFunction from '../../../apis/useAxiosFn';

const OrderHistoryInformation = ({ actions, sourceData, orderStatus, option, cycleOrderID }) => {
    const { getOptionMapFn } = useCommomFn();
    const Axios = useCallback(useAxiosFunction(actions), []);
    let { records } = sourceData;

    const handleOnRecoveryClick = async () => {
        await Axios.put(
            `/api/cycleorders/recovery/${cycleOrderID}`,
            { recoveryTime: moment().format('yyyy/MM') },
            {},
            {
                callbackFn: (result, respondData) => {
                    window.location.reload();
                },
            }
        );
    };

    const PaymentStatus = () => {
        const text = getOptionMapFn(option, orderStatus);
        const style = orderStatus === '5' ? 'primary-bg' : orderStatus === '6' ? 'secondary-bg' : '';
        return (
            <div className={clsx('curves-btn-small', style)}>
                <span className="text-1x">{text}</span>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="flex">
                <span>訂購狀態：</span>
                <span className="child-X-Gutters-1">
                    {_.map(records, (target) => (
                        <div key={uuid()} className="text-highlight">
                            {target}
                            <Button
                                style={{ marginLeft: 6 }}
                                label="恢復"
                                width={50}
                                height={18}
                                fontSize={12}
                                color="auxiliary_d"
                                fullColor
                                handleOnClick={handleOnRecoveryClick}
                            />
                        </div>
                    ))}
                </span>
            </div>
            {(orderStatus === '5' || orderStatus === '6') && <PaymentStatus />}
        </React.Fragment>
    );
};

const CycleOrderHeader = ({ actions, sourceData, optionData, orderStatus, cycleOrderID, ...props }) => {
    const { orderID, updaterName, updatedDate, cycleOrderHistory } = sourceData;
    const deliveredDateString =
        cycleOrderHistory?.deliveryTime ?? ''
            ? moment(cycleOrderHistory.deliveryTime).format('YYYY/MM/DD')
            : '尚未出貨';
    const updatedDateString = moment(updatedDate).format('YYYY/MM/DD');
    const { statusOption } = optionData;

    return (
        <React.Fragment>
            {orderStatus !== -1 && (
                <div className="view-normal-width container-root child-X-Gutters-4">
                    <div className="flex flex-jc-space-between">
                        <div>
                            <span>訂單編號</span>
                            <span>：</span>
                            <span>{!_.isEmpty(sourceData) && orderID}</span>
                        </div>
                    </div>
                    <div className="flex flex-jc-space-between">
                        <div>
                            <span>更新者</span>
                            <span>：</span>
                            <span>{updaterName}</span>
                        </div>
                        <div>
                            <span>更新時間</span>
                            <span>：</span>
                            <span>{updatedDateString}</span>
                        </div>
                        <div>
                            <span>上次出貨</span>
                            <span>：</span>
                            <span>{deliveredDateString}</span>
                        </div>
                    </div>
                    <div className="flex flex-jc-space-between">
                        <OrderHistoryInformation
                            cycleOrderID={cycleOrderID}
                            actions={actions}
                            sourceData={cycleOrderHistory}
                            orderStatus={_.toString(orderStatus)}
                            option={statusOption}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default CycleOrderHeader;
