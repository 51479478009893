import React, { useRef, useEffect, useCallback, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';

import SuspendCard from './components/SuspendCard';
import UnsubscribeCard from './components/UnsubscribeCard';
import RecoveryCard from './components/RecoveryCard';
import CycleOrderCard from './components/CycleOrderCard';
import MemberCard from './components/MemberCard';
import StoreCoachCard from './components/StoreCoachCard';
import ProductShipmentCard from './components/ProductShipmentCard';
import PaymentCard from './components/PaymentCard';
import OrderRecordTable from './components/OrderRecordTable';
// img
import suspendIcon from '../../../images/suspendIcon.svg';
import unsubscribeIcon from '../../../images/unsubscribeIcon.svg';
import backArrow from '../../../images/backArrow.svg';
import restore from '../../../images/restore.svg';

import { Button, Card } from '../../components';
import { useHistory } from 'react-router-dom';
import useCommomFn from '../../common/useCommomFn';
import useAxiosFunction from '../../apis/useAxiosFn';
import Axios from 'axios';
import _ from 'lodash';

// @material-ui
import { Tabs, Tab } from '@material-ui/core';
import CycleOrderHeader from './components/CycleOrderHeader';

const returnPage = '/order/cycleorderlist';

const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
        <Fragment>
            {value === index &&
                (_.isArray(children)
                    ? children.map((source, idx) => <Fragment key={idx}>{source}</Fragment>)
                    : children)}
        </Fragment>
    );
};

const CycleOrderStatusIcon = ({
    orderStatus,
    presetOrderStatus,
    SuspendCardPanel,
    UnsubscribeIconCardPanel,
    RecoveryCardPanel,
    setPresetOrderStatus,
}) => {
    const history = useHistory();
    return (
        <div className="flex child-Y-Gutters-4">
            {orderStatus === 4 && !presetOrderStatus && (
                <React.Fragment>
                    <span>
                        <img
                            className="cursor-pointer"
                            src={suspendIcon}
                            alt="暫停"
                            title="暫停"
                            onClick={() => {
                                setPresetOrderStatus(5);
                            }}
                        />
                    </span>
                    <span>
                        <img
                            className="cursor-pointer"
                            src={unsubscribeIcon}
                            alt="退訂"
                            title="退訂"
                            onClick={() => {
                                setPresetOrderStatus(6);
                            }}
                        />
                    </span>
                </React.Fragment>
            )}
            {(orderStatus === 6 || orderStatus === 5) && !presetOrderStatus && (
                <span>
                    <img
                        className="cursor-pointer"
                        src={restore}
                        alt="恢復訂單"
                        title="恢復訂單"
                        onClick={() => {
                            setPresetOrderStatus(orderStatus);
                        }}
                    />
                </span>
            )}
            {orderStatus !== -1 && !(SuspendCardPanel || UnsubscribeIconCardPanel || RecoveryCardPanel) && (
                <span>
                    <img
                        className="cursor-pointer"
                        src={backArrow}
                        alt="返回"
                        title="返回列表清單"
                        onClick={() => {
                            history.push('/order/cycleorderlist', { isBack: true });
                        }}
                    />
                </span>
            )}
        </div>
    );
};

const CycleOrderEdit = ({ actions, ...props }) => {
    const history = useHistory();
    const AxiosApi = useCallback(useAxiosFunction(actions), []);
    const { showLoading } = actions;
    const { location } = props;
    const { getResultFn } = useCommomFn();
    const [sourceData, setSourceData] = useState({});
    const [cycleOrderID, setCycleOrderID] = useState('');
    const [orderStatus] = useState(location.state?.orderStatus ?? '');
    const [presetOrderStatus, setPresetOrderStatus] = useState('');
    const [isMount, setIsMount] = useState(false);
    const [tabValue, setTabValue] = useState('SETUP');
    
    //循環訂單歷史資訊
    const CycleOrderCardRef = useRef();
    //會員資訊
    const memberInfomationRef = useRef();
    //門店教練資訊
    const storeCoachRef = useRef();
    //商品出貨
    const productShipmentRef = useRef();
    //付款資訊
    const PaymentCardRef = useRef();

    const getResult = () => {
        let assembleParams = {};
        const orderrecurrenceinfoValue = CycleOrderCardRef.current.getResult();
        const memberInfoValue = memberInfomationRef.current.getResult();
        const storeCoachValue = storeCoachRef.current.getResult();
        const productShipmentValue = productShipmentRef.current?.getResult() ?? {};
        const paymentInfoValue = PaymentCardRef.current?.getResult() ?? {};
        Object.assign(assembleParams, { ...orderrecurrenceinfoValue });
        assembleParams.memberInfo = { ...memberInfoValue };
        assembleParams.shopCoachInfo = { ...storeCoachValue };
        assembleParams.shipInfo = { ...productShipmentValue };
        assembleParams.paymentInfo = { ...paymentInfoValue };
        return assembleParams;
    };

    const initialValue = useCallback(
        async (cycleorderId) => {
            showLoading(true);
            await Axios.all([
                cycleorderId && Axios.get(`/api/cycleorders/${cycleorderId}`),
                Axios.get(`/api/cycleorderoptions`),
            ])
                .then(
                    Axios.spread((cycleordersParams = {}, optionParams = {}) => {
                        const cycleOrderInfo = getResultFn({}, cycleordersParams.data);

                        const {
                            invoiceMethod,
                            invoiceType,
                            paymentType,
                            cycleOrderStatus,
                            timeType,
                            toolType,
                            shops,
                            products,
                            orderStatus,
                        } = getResultFn([], optionParams.data);

                        const CycleOrderCardOptions = {
                            statusOption: cycleOrderStatus,
                            timeOption: timeType,
                            toolOption: toolType,
                        };
                        const storeCoachInformationOptions = {
                            shopOption: shops,
                        };
                        const productShipmentOptions = {
                            productOption: products,
                        };
                        const PaymentCardOptions = {
                            paymentTypeOption: paymentType,
                            invoiceTypeOption: invoiceType,
                            invoiceMethodOption: invoiceMethod,
                        };
                        const orderRecordOptions = {
                            orderStatusOption: orderStatus,
                        };

                        setSourceData({
                            cycleOrderInfo: cycleOrderInfo || {},
                            orderRecord: {},
                            cycleOrderHistory: {},
                            CycleOrderCardOptions,
                            storeCoachInformationOptions,
                            productShipmentOptions,
                            PaymentCardOptions,
                            orderRecordOptions,
                        });
                        setCycleOrderID(cycleorderId);
                        setIsMount(true);
                        showLoading(false);
                    })
                )
                .catch((error) => {
                    console.log(error);
                    showLoading(false);
                });
        },
        [getResultFn, showLoading]
    );

    const handleOnClick = (e) => {
        const params = getResult();
        if (orderStatus === -1) handleAddApi(params);
        else handleEditApi(params);
    };

    const handleEditApi = async (params) => {
        await AxiosApi.put(
            `/api/cycleorders/${cycleOrderID}`,
            params,
            {},
            {
                callbackFn: (result, respondData) => {
                    alert(respondData.data.message);
                    //history.push(returnPage);
                },
            }
        );
    };

    const handleAddApi = async (params) => {
        await AxiosApi.post(
            `/api/cycleorders/add`,
            params,
            {},
            {
                callbackFn: (result, respondData) => {
                    alert(respondData.data.message);
                    history.push(returnPage);
                },
            }
        );
    };

    const handleTabsOnChange = (e, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (cycleOrderID) {
            Axios.get(`/api/cycleorders/${cycleOrderID}/history`).then((res) => {
                if (res.data.success) {
                    const { orderHistory: orderRecord, timeline: cycleOrderHistory } = res.data.result;
                    setSourceData((prev) => ({ ...prev, orderRecord, cycleOrderHistory }));
                }
            });
        }
    }, [cycleOrderID]);

    useEffect(() => {
        const { state } = location;
        if (state) initialValue(state.curData.cycleOrderID || '');
        else history.push(returnPage);
        return () => {
            setIsMount(false);
        };
    }, [location, history, initialValue]);

    const isReadOnly = orderStatus === 6 || presetOrderStatus === 6 || presetOrderStatus === 5;
    const SuspendCardPanel = presetOrderStatus === 5 && orderStatus !== 5;
    const UnsubscribeIconCardPanel = presetOrderStatus === 6 && orderStatus !== 6;
    const RecoveryCardPanel =
        (orderStatus === 5 && presetOrderStatus === 5) || (orderStatus === 6 && presetOrderStatus === 6);

    const { cycleOrderInfo } = sourceData;

    return (
        <React.Fragment>
            {isMount && (
                <React.Fragment>
                    <CycleOrderHeader
                        sourceData={{
                            ...cycleOrderInfo,
                            cycleOrderHistory: sourceData.cycleOrderHistory,
                        }}
                        optionData={sourceData.CycleOrderCardOptions}
                        orderStatus={orderStatus}
                        actions={actions}
                        cycleOrderID={cycleOrderID}
                    />
                    {SuspendCardPanel && (
                        <SuspendCard cycleOrderID={cycleOrderID} setPresetOrderStatus={setPresetOrderStatus} />
                    )}
                    {UnsubscribeIconCardPanel && (
                        <UnsubscribeCard
                            cycleOrderID={cycleOrderID}
                            setPresetOrderStatus={setPresetOrderStatus}
                            tmpSourceData={cycleOrderInfo.shipInfo}
                            sourceData={{
                                productsType: [{ id: 1, productName: '' }],
                            }}
                        />
                    )}
                    {RecoveryCardPanel && (
                        <RecoveryCard
                            cycleOrderID={cycleOrderID}
                            setPresetOrderStatus={setPresetOrderStatus}
                            sourceData={{ ...sourceData.cycleOrderHistory }}
                        />
                    )}
                    <div className="tabs-root">
                        {orderStatus !== -1 && (
                            <Tabs
                                value={tabValue}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleTabsOnChange}
                            >
                                <Tab label="循環設定" value="SETUP" />
                                <Tab label="訂單記錄" value="RECORD" />
                            </Tabs>
                        )}
                        <CycleOrderStatusIcon
                            orderStatus={orderStatus}
                            presetOrderStatus={presetOrderStatus}
                            setPresetOrderStatus={setPresetOrderStatus}
                            SuspendCardPanel={SuspendCardPanel}
                            UnsubscribeIconCardPanel={UnsubscribeIconCardPanel}
                            RecoveryCardPanel={RecoveryCardPanel}
                        />
                    </div>
                    <TabPanel value={tabValue} index={'SETUP'}>
                        <CycleOrderCard
                            ref={CycleOrderCardRef}
                            sourceData={cycleOrderInfo}
                            optionData={sourceData.CycleOrderCardOptions}
                            orderStatus={orderStatus}
                            actions={actions}
                            cycleOrderID={cycleOrderID}
                        />
                        <MemberCard
                            ref={memberInfomationRef}
                            sourceData={cycleOrderInfo.memberInfo || {}}
                            disabled={isReadOnly}
                        />
                        <StoreCoachCard
                            ref={storeCoachRef}
                            sourceData={cycleOrderInfo.shopCoachInfo || {}}
                            optionData={sourceData.storeCoachInformationOptions}
                            disabled={isReadOnly}
                        />
                        <ProductShipmentCard
                            ref={productShipmentRef}
                            sourceData={cycleOrderInfo.shipInfo || {}}
                            optionData={sourceData.productShipmentOptions}
                            disabled={isReadOnly}
                        />
                        <PaymentCard
                            ref={PaymentCardRef}
                            sourceData={cycleOrderInfo.paymentInfo || {}}
                            optionData={sourceData.PaymentCardOptions}
                            disabled={isReadOnly}
                        />
                        {!(isReadOnly || presetOrderStatus === 5 || presetOrderStatus === 6) && (
                            <div className="view-normal-width container-root container-disable-gutters">
                                <div className="flex flex-jc-center button-root">
                                    <Button
                                        label={`取消`}
                                        color="auxiliary_b"
                                        handleOnClick={() => {
                                            history.push(returnPage);
                                        }}
                                    />
                                    <Button
                                        label={(orderStatus === -1 ? `新增` : `編輯`) + `送出`}
                                        fullColor
                                        color="auxiliary_b"
                                        handleOnClick={handleOnClick}
                                    />
                                </div>
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel value={tabValue} index={'RECORD'}>
                        <Card>
                            <OrderRecordTable
                                sourceData={sourceData.orderRecord || []}
                                optionData={sourceData.orderRecordOptions}
                            />
                        </Card>
                    </TabPanel>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(CycleOrderEdit);
