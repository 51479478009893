import * as React from 'react';

import Subject from './components/Subject';
import UserInfo from './components/UserInfo';

const TopBar = () => {
    return (
        <React.Fragment>
            <div className="top-header">
                <div className="top-header-left">
                    <Subject />
                </div>
                <div className="top-header-right">
                    <UserInfo />
                </div>
                <div className="hr"></div>
            </div>
        </React.Fragment>
    );
};

export default TopBar;
