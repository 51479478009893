import React, { useImperativeHandle, useEffect } from 'react';
import Backdrop from './Backdrop';
import Loading from './Loading';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// action
import { setLoadingData } from '../../../redux/loading/action';
// select
import { getLoadingData } from '../../../redux/loading/selector';

const ShowLoading = React.forwardRef(({ size, duration, color = 'main', ...props }, ref) => {
    const { showLoading } = props;
    let showCount = showLoading.count !== 0;

    const [open, setOpen] = React.useState(false);

    const handleToggle = (value) => {
        setOpen(value);
    };

    useImperativeHandle(ref, () => ({
        handleToggle: () => {
            handleToggle(!showCount);
        },
    }));

    useEffect(() => {
        setOpen(showCount);
    }, [showCount]);

    return (
        <Backdrop isOpen={open} className="dialog-loading">
            <Loading size={50} duration={700} />
        </Backdrop>
    );
});

const mapStateToProps = (state) => ({
    showLoading: getLoadingData(state),
});

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setLoadingData,
    };
    return {
        actions: bindActionCreators(actions, dispatch),
        dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowLoading);
