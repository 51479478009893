import React, { useRef, useCallback, useState, useEffect } from 'react';
import CycleOrderExportCard from './components/CycleOrderExportCard';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';
import { Button } from '../../components';
import { useHistory } from 'react-router-dom';
import useAxiosFunction from '../../apis/useAxiosFn';
import qs from 'qs';
import _ from 'lodash';

const CycleOrderExport = ({ actions, ...props }) => {
    const history = useHistory();
    const Axios = useCallback(useAxiosFunction(actions), []);
    const [options, setOptions] = useState({});
    const cycleOrderExportCardRef = useRef();

    const initialValue = useCallback(async () => {
        const params = { hasAll: true };
        const apiShopOptions = await Axios.get('/api/shopoptions/options', { params });
        const apiShipDaysOptions = await Axios.get('/api/cycleorderoptions/shipCycleSearchBar', { params });
        const apiPlatformTypeOptions = await Axios.get('/api/cycleorderoptions/SystemType', { params });
        const apiOptions = {
            shopOptions: apiShopOptions,
            shipDaysOptions: apiShipDaysOptions,
            platformTypeOptions: apiPlatformTypeOptions,
        };
        setOptions(apiOptions);
    }, [Axios]);

    const handleDownloadOnClick = (e) => {
        e.preventDefault();
        let queryString = qs.stringify(cycleOrderExportCardRef.current.getResult());
        alert('另開分頁下載中');
        queryString = `${window.location.origin}/api/cycleorderexport/detailed/output.xlsx?${queryString}`;
        window.open(queryString);
    };

    const handleTransferOnClick = (e) => {
        const newData = cycleOrderExportCardRef.current.getResult();
        if (newData.length === 0) return;
        history.push('/member/transfer', newData);
    };

    useEffect(() => {
        initialValue();
    }, [initialValue]);

    return (
        <React.Fragment>
            {!_.isEmpty(options) && (
                <CycleOrderExportCard ref={cycleOrderExportCardRef} options={options} defaultData={{ shopID: 0 }} />
            )}
            <div className="view-normal-width container-root container-disable-gutters">
                <div className="flex flex-jc-center button-root">
                    <Button label="下載" color="auxiliary_b" fullColor handleOnClick={handleDownloadOnClick} />
                    <Button
                        label="會籍轉移(總表至iCoash)"
                        color="auxiliary_a"
                        width={220}
                        fullColor
                        handleOnClick={handleTransferOnClick}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(CycleOrderExport);
