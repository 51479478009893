import * as React from 'react';

// @material-ui
import { makeStyles } from '@material-ui/core';

// img
import Portrait from '../../../images/portrait.svg';
// import Bells from '../../../images/bells.svg';
// import Outline from '../../../images/outline.svg';
import Logout from '../../../images/logout.svg';
import { useHistory } from 'react-router-dom';

import { localUser } from '../../util';
import Axios from 'axios';

const useStyles = makeStyles(() => {
    return {
        TopBarUserInfoLeft: {
            fontSize: '1rem',
            display: 'flex',
            alignItems: 'center',
            '& span + span': {
                padding: '0 0 0 20px',
            },
        },
    };
});

const UserInfo = () => {
    const history = useHistory();
    const handleLogout = async (e) => {
        await Axios.get('api/logout', {}).then((res) => {
            const { data } = res;
            if (data.success) {
                localUser.logout();
                history.push('/');
            }
        });
    };

    const user = localUser.get();

    if (!user) handleLogout();
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.TopBarUserInfoLeft}>
                <span>{user.displayName}</span>
                <span>
                    <img className="cursor-pointer" src={Portrait} alt="portrait" />
                </span>
                {/* <span>
                    <img className="cursor-pointer" src={Bells} alt="bells" />
                </span>
                <span>
                    <img className="cursor-pointer" src={Outline} alt="outline" />
                </span> */}
                <span>
                    <img className="cursor-pointer" title="登出" src={Logout} alt="logout" onClick={handleLogout} />
                </span>
            </div>
        </React.Fragment>
    );
};

export default UserInfo;
