import React from 'react';
import { makeStyles, FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import _ from 'lodash';

const Select = ({
    sourceData,
    label = '',
    inputRef,
    width = 220,
    handleOnChange: propsHandleOnChange,
    firstOptionDisable,
    noEmptyOption,
    defaultValue,
    ...props
}) => {
    const clsses = makeStyles(() => ({
        textWidth: {
            width,
        },
    }))();

    const handleOnChange = (e) => {
        const { value: val } = e.target;
        propsHandleOnChange && propsHandleOnChange(val, e);
    };

    return (
        <FormControl margin="normal" className={clsses.textWidth}>
            {label && <InputLabel shrink>{label}</InputLabel>}
            <NativeSelect
                inputRef={inputRef || null}
                onChange={handleOnChange}
                defaultValue={_.toString(defaultValue)}
                {...props}
            >
                {!noEmptyOption && <option value="" disabled={firstOptionDisable}></option>}
                {!_.isEmpty(sourceData)
                    ? sourceData.map((target,idx) => (
                          <option key={idx} value={target.value}>
                              {target.text}
                          </option>
                      ))
                    : ''}
            </NativeSelect>
        </FormControl>
    );
};

// Select.propTypes = {
//     label: PropTypes.string,
//     name: PropTypes.string,
//     inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
//     width: PropTypes.number,
//     sourceData: PropTypes.
// };

export default Select;
