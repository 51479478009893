import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableBody, TableCell, TableContainer, Table } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

const Tables = ({ children, isPagination = false, className, size = 'medium', maxHeight, ...props }) => {
    const { count = 1, page = 1, handleChangePage } = props;
    const classes = makeStyles({
        maxHeight: { maxHeight: _.parseInt(maxHeight) },
    })();
    let tableContainerParams = {};
    let tableParams = { className, size };
    if (maxHeight) {
        tableContainerParams.className = classes.maxHeight;
        tableParams.stickyHeader = true;
    }

    const onChange = (...others) => {
        handleChangePage && handleChangePage(...others);
    };

    return (
        <React.Fragment>
            <TableContainer {...tableContainerParams}>
                <Table {...tableParams}>{children}</Table>
                {isPagination && (
                    <Pagination
                        className="pagination"
                        page={page}
                        count={count}
                        color="secondary"
                        siblingCount={0}
                        boundaryCount={2}
                        onChange={onChange}
                    />
                )}
            </TableContainer>
        </React.Fragment>
    );
};

// header Iterator Element
Tables.TableRowHeader = ({ headerLabel = [], ...props }) => {
    return (
        <TableHead>
            <TableRow>
                {headerLabel.map((target) => {
                    const { align = 'left', minWidth = 80, label } = target;
                    let property = {
                        align,
                        style: { minWidth },
                        key: uuid(),
                        className: 'ban-select',
                    };
                    return <TableCell {...property}>{_.isFunction(label) ? label({ ...props }) : label}</TableCell>;
                })}
            </TableRow>
        </TableHead>
    );
};

// body Iterator Element
Tables.TableRowBody = ({ headerLabel = [], sourceData = [], children, emptyText, ...props }) => {
    return (
        <TableBody>
            {!_.isEmpty(sourceData) ? (
                <React.Fragment>
                    {sourceData.map((target) => {
                        return (
                            <TableRow hover tabIndex={-1} key={uuid()}>
                                <Tables.TableRowCell
                                    headerLabel={headerLabel}
                                    defaultData={target}
                                    sourceData={sourceData}
                                    {...props}
                                />
                            </TableRow>
                        );
                    })}
                    {children}
                </React.Fragment>
            ) : (
                <TableRow tabIndex={-1}>
                    <TableCell colSpan={headerLabel.length}>
                        <Alert variant="outlined" severity="info">
                            {emptyText || '尚無資料'}
                        </Alert>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

Tables.TableRowCell = ({ headerLabel, defaultData, sourceData, ...props }) => {
    return (
        <React.Fragment>
            {headerLabel.map((target) => {
                const value =
                    typeof defaultData[target.labelKey] === 'object'
                        ? target.labelKey
                        : defaultData[target.labelKey] || '';
                const { align = 'left' } = target;
                let property = {
                    align,
                    key: uuid(),
                };
                return (
                    <TableCell {...property}>
                        {_.isFunction(target.format)
                            ? target.format(value, defaultData, { ...props, sourceData })
                            : value}
                    </TableCell>
                );
            })}
        </React.Fragment>
    );
};

export default Tables;
