import React from 'react';
import DialogModal from '../../components/dialog/DialogModal';
import { Button, TextInput, Select } from '../../components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';

const CategoriesAddDialog = ({ options, categoryId, actions }) => {
    const { dialogModal } = actions;
    const currentOption = options[categoryId] || {};
    const { nodes } = currentOption;
    const stage = nodes ? _.split(nodes, '-').length : 0;
    let option = _.filter(_.values(options), (target) => _.split(target.nodes, '-').length === stage);
    option = _.map(option, (target) => ({ ...target, text: target.name, value: target.id }));

    const handleEditClick = (e) => {
        dialogModal({ isOpen: false });
    };

    return (
        <React.Fragment>
            <DialogModal.Header>
                <h2>新增分類</h2>
            </DialogModal.Header>
            <DialogModal.Body>
                <div className="category-dialog-content">
                    {!_.isEmpty(currentOption) && (
                        <Select
                            width={300}
                            label="父層分類"
                            sourceData={option}
                            noEmptyOption
                            defaultValue={categoryId}
                        />
                    )}
                    <TextInput width={300} label="名稱" />
                </div>
            </DialogModal.Body>
            <DialogModal.Footer>
                <div className="category-dialog-button">
                    <Button color="auxiliary_c" onClick={handleEditClick} fullColor>
                        送出資料
                    </Button>
                </div>
            </DialogModal.Footer>
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('dialogModal'))(CategoriesAddDialog);
