import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './scripts/login';
import Home from './scripts/home';
import { PrivateRoute } from './scripts/components';
import { ShowLoading } from './scripts/components/dialog';
import DialogModals from './scripts/components/dialog/DialogModals';

// import PrivateRoute from './components/Route';
// import { NotFoundView, UnauthorizedView, ForbiddenView, ServerErrorView } from './containers/ErrorPage';

const App = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/login" component={Login} />
                <PrivateRoute component={Home} />
            </Switch>
            <ShowLoading />
            <DialogModals />
        </React.Fragment>
    );
};

export default App;