import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { intersectionFn } from './common';
import _ from 'lodash';

const useQuery = () => {
    const location = useLocation();
    const history = useHistory();
    const value = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

    return {
        value,
        set: (params) => {
            sessionStorage.setItem(history.location.pathname, `${JSON.stringify(params)}`);
            history.push({
                pathname: location.pathname,
                search: qs.stringify({ ...value, ...params }),
            });
        },
        preQueryString: (source, { isBack, isQueryString, isInitial }) => {
            if (isQueryString) {
                if (_.isEmpty(source) || !isInitial) return source;
                let preURLString = sessionStorage.getItem(history.location.pathname);
                if (isBack && !!preURLString) {
                    preURLString = JSON.parse(preURLString);
                    source = intersectionFn(preURLString, source);
                } else {
                    source = intersectionFn(value, source);
                }
            }
            return source;
        },
    };
};

export default useQuery;
