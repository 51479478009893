import React, { useEffect, useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/dispatch';
// @material-ui
import { makeStyles, Box, List } from '@material-ui/core';

// import Axios from 'axios';
import routes from '../home/routes';
import NavSubject from './components/NavSubject';
import Logo from '../../images/nav_logo.svg';
import useCommomFn from '../common/useCommomFn';
import useAxiosFunction from '../apis/useAxiosFn';

const useStyles = makeStyles(() => {
    return {
        'main-menu': {
            background: '#fff',
            minHeight: '100vh',
            width: 280,
            position: 'fixed',
            padding: '30px 0 60px 0',
            boxShadow: '0 3px 6px rgba(0,0,0,.16)',
            '& .logo': {
                textAlign: 'center',
                width: '100%',
                display: 'block',
                '& > img': {
                    height: 'auto',
                    verticalAlign: 'middle',
                },
            },
            '& .version': {
                width: '130px',
                margin: '5px auto 0',
                textAlign: 'right',
            },
            '& .version-w': {
                color: '#fff',
            },
            '& > :nth-child(-n + 2)': {
                borderBottom: '1px solid rgba(255, 255, 255, .1)',
            },
        },
    };
});

const Nav = ({ actions }) => {
    const classes = useStyles();
    const [menu, setMenu] = useState([]);
    const [version, setVersion] = useState();
    const { getMenuRoutesFn } = useCommomFn();
    const AxiosApi = useCallback(useAxiosFunction(actions), []);
    const [colmap, setColmap] = useState(parseInt(sessionStorage.getItem('colmap') ?? 0));

    const initialValue = useCallback(async () => {
        const menus = getMenuRoutesFn(routes.children);
        setMenu(menus);

        const result = await AxiosApi.get(`/api/parameters/systemsetting/version`);

        if (result && result.length > 0) {
            setVersion(result[0].value);
        }
    }, [getMenuRoutesFn, AxiosApi]);

    const handleCollapseChange = (id) => () => {
        let sourceId = id;
        if (sessionStorage.getItem('colmap') === sourceId.toString()) {
            sourceId = 0;
            sessionStorage.setItem('colmap', sourceId);
        } else {
            sessionStorage.setItem('colmap', sourceId);
        }
        setColmap(sourceId);
    };

    useEffect(() => {
        initialValue();
    }, [initialValue]);
    
    return (
        <Box component="nav" display="flex" flexDirection="column" className={classes['main-menu']} zIndex={2}>
            <Box alignItems="center">
                <RouterLink to="/" className="logo">
                    <img src={Logo} alt="Curves" />
                </RouterLink>
                <div className={clsx('version', { 'version-w': version === 'Production' })}>{version}</div>
            </Box>
            <Box paddingTop="24px">
                <List>
                    {menu.map((target) => (
                        <NavSubject
                            key={target.id}
                            sourceData={target}
                            isOpen={colmap === target.id}
                            handleCollapseChange={handleCollapseChange}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(Nav);
