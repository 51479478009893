import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';

const TextInput = ({
    label = '',
    name,
    inputRef,
    width = 220,
    fullWidth,
    type = 'string',
    defaultValue: propsDefaultValue = '',
    required,
    ...props
}) => {

    const clsses = makeStyles(() => ({
        textWidth: {
            width: fullWidth ? '100%' : width,
        },
    }))();

    const defaultProps = {
        margin: 'normal',
        className: clsses.textWidth,
        type,
        label,
        name,
        ...props,
        required,
        InputLabelProps: { shrink: true },
    };

    // AutocompleteInput have props.inputProps
    if (!props.inputProps) defaultProps.defaultValue = _.toString(propsDefaultValue);

    return <TextField {...defaultProps} inputRef={inputRef ?? undefined}/>;
};

TextInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.string,
};

export default TextInput;
