import React, { useState, useRef } from 'react';
// @material-ui
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import { Button } from '.';
import _ from 'lodash';

const ButtonSelector = ({ handleExcelDownload, options = [], ...props }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    return (
        <React.Fragment>
            <span ref={anchorRef}>
                <Button {...props} handleOnClick={handleToggle} />
            </span>
            {!_.isEmpty(options) && (
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        {options.map(({ text, value }, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={(event) => {
                                                    setOpen(false);
                                                    handleExcelDownload(event, value);
                                                }}
                                            >
                                                {text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            )}
        </React.Fragment>
    );
};

export default ButtonSelector;
