import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Backdrop from './Backdrop';
import _ from 'lodash';

const DELAY_TIME = 300;

const DialogModal = ({ open = false, className, overlayFn, children, ...props }) => {
    const [showBox, setShowBox] = useState(false);

    const defaultProps = {
        isOpen: open,
        onAfterOpen: () => setShowBox(true),
        onRequestClose: _.isFunction(overlayFn) ? () => overlayFn(false) : null,
        shouldCloseOnOverlayClick: _.isFunction(overlayFn),
        closeTimeoutMS: DELAY_TIME,
    };

    useEffect(() => {
        !open && setShowBox(open);
    }, [open]);

    return (
        <Backdrop {...defaultProps}>
            <CSSTransition in={showBox} timeout={DELAY_TIME} classNames={className} unmountOnExit>
                <div className="dialog-box">{children}</div>
            </CSSTransition>
        </Backdrop>
    );
};

DialogModal.Header = ({ children, ...props }) => {
    return <div className="dialog-box-header">{children}</div>;
};

DialogModal.Body = ({ children, ...props }) => {
    return <div className="dialog-box-body">{children}</div>;
};

DialogModal.Footer = ({ children, ...props }) => {
    return <div className="dialog-box-footer">{children}</div>;
};

export default DialogModal;
