import React from 'react';
import { Checkbox as MaterialCheckbox, FormControlLabel } from '@material-ui/core';
import _ from 'lodash';

const WrappedCheckBox = ({ inputRef = null, defaultChecked, required, disabled, indeterminate, ...props }) => {
    return (
        <MaterialCheckbox
            defaultChecked={defaultChecked}
            inputRef={(e) => {
                if(!e) return;
                if (_.isArray(inputRef.current)) inputRef.current.push(e);
                else inputRef.current = e;
            }}
            indeterminate={indeterminate}
            required={required}
            disabled={disabled}
            {...props}
        />
    );
};

const hocFormControlLabel = (Component) => ({ label, ...props }) => {
    return label ? (
        <FormControlLabel label={label}>
            <Component {...props} />
        </FormControlLabel>
    ) : (
        <Component {...props} />
    );
};

const Checkbox = ({ ...props }) => {
    const CustomCheckbox = hocFormControlLabel(WrappedCheckBox);
    return <CustomCheckbox {...props} />;
};

export default Checkbox;
