import React, { useRef } from 'react';

import { TextInput } from './';
import NumberFormat from 'react-number-format';

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange(values);
            }}
            thousandSeparator
            isNumericString
        />
    );
}

const NumberInput = ({ label = '', name, width = 240, prefix, ...props }) => {
    const allenRef = useRef();
    const handleOnChange = (values) => {
        console.log(values.floatValue);
    };

    return (
        <TextInput
            label={label}
            name={name}
            inputRef={allenRef}
            onChange={handleOnChange}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
        />
    );
};

export default NumberInput;
