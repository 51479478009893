import React, { useImperativeHandle, useRef } from 'react';
import { Select, TextInput, Card } from '../../../components';

const CycleOrderCard = ({ sourceData, optionData, orderStatus, ...props }, ref) => {
    const { orderID, time, tool } = sourceData;
    const { timeOption, toolOption } = optionData;
    const orderIDRef = useRef();
    const timeRef = useRef();
    const toolRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            return {
                orderID: orderStatus === -1 ? orderIDRef.current.value : orderID,
                time: timeRef.current.value,
                tool: toolRef.current.value,
            };
        },
    }));

    return (
        <Card>
            <div>
                <h4 className="card-title">訂單</h4>
            </div>
            <div className="form-flex-row">
                {orderStatus === -1 && <TextInput inputRef={orderIDRef} label="訂單編號" />}
                <Select sourceData={timeOption} label="TIME" inputRef={timeRef} defaultValue={time} />
                <Select sourceData={toolOption} label="TOOL" inputRef={toolRef} defaultValue={tool} />
                {orderStatus !== -1 && <div className="makeStyles-textWidth-18"></div>}
            </div>
        </Card>
    );
};

export default React.forwardRef(CycleOrderCard);
