// redux
import { combineReducers } from 'redux';
// types
import { SET_DIALOG_DATA } from './types';

const moduleBase = { isOpen: false, backdropClose: false, className: '', Render: null };

export function DialogData(state = { Data: { ...moduleBase } }, action) {
    switch (action.type) {
        case SET_DIALOG_DATA:
            if (action.Data === null) {
                action.Data = { ...moduleBase };
            }
            return {
                Data: { ...state.Data, ...action.Data },
            };

        default:
            return state;
    }
}

const Reducer = combineReducers({
    DialogData,
});

export default Reducer;
