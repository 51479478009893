import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';

import CycleOrderTable from './components/CycleOrderTable';
import { Card, Button, ButtonSelector, SearchInput, UpLoadFileButton } from '../../components';
import { useHistory } from 'react-router-dom';
import useAxiosFunction from '../../apis/useAxiosFn';
import moment from 'moment';

const daysOption = [
    {
        text: '全部',
        value: '1',
    },
    {
        text: '10日',
        value: '10',
    },
    {
        text: '20日',
        value: '20',
    },
];

const CycleOrderList = ({ actions, ...props }) => {
    const Axios = useCallback(useAxiosFunction(actions), []);
    const [isMount, setIsMount] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const history = useHistory();
    const searchRef = useRef();

    const initialValue = useCallback(async () => {
        const apiData = await Axios.post(
            'api/cycleorders',
            {
                name: '',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                pageIndex: 1,
                pageSize: 20,
            },
            {},
            {
                isInitial: true,
                isQueryString: true,
                isBack: history.location?.state?.isBack ?? false,
            }
        );
        if (apiData) {
            setSourceData({ ...apiData });
            setIsMount(true);
        }
        return () => {
            setIsMount(false);
        };
    }, [Axios, history]);

    const handleChangePage = async (e, num, searchString) => {
        // 換頁時抓取input search
        if (searchString === undefined) searchString = searchRef.current.value;
        const apiData = await Axios.post(
            'api/cycleorders',
            {
                name: searchString,
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                pageIndex: num,
                pageSize: 20,
            },
            {},
            {
                isQueryString: true,
            }
        );
        if (apiData) setSourceData({ ...apiData });
    };

    // do submit after newValue is undefined
    const handleSearchInput = (e, newValue) => {
        e.preventDefault();
        if (newValue === undefined) newValue = searchRef.current.value;
        handleChangePage(e, 1, newValue);
    };

    const handleExcelDownload = async (e, value) => {
        e.preventDefault();
        let queryString = '?estimateday=';
        if (value === '1') queryString = '';
        else queryString += value;
        alert('另開分頁下載中');
        queryString = `${window.location.origin}/api/cycleorderexport/output.xlsx${queryString}`;
        window.open(queryString);
    };

    const handleAddCycleOrder = (e) => {
        history.push(`/order/cycleorderedit/add`, {
            curData: {},
            orderStatus: -1,
        });
    };

    useEffect(() => {
        initialValue();
    }, [initialValue]);

    return (
        <React.Fragment>
            <Card noWidth>
                <div className="flex flex-ai-center flex-jc-space-between">
                    <form onSubmit={handleSearchInput}>
                        <SearchInput
                            width={380}
                            style={{ justifyContent: 'flex-end' }}
                            placeholder="查詢會員編號．姓名電話．訂單編號"
                            handleOnClick={handleSearchInput}
                            searchRef={searchRef}
                        />
                    </form>
                    <div className="flex flex-column-gap-1">
                        <Button width={100} color="auxiliary_c" fullColor handleOnClick={handleAddCycleOrder}>
                            新增
                        </Button>
                        <UpLoadFileButton width={100} url={`api/cycleorderimport/upload`} color="auxiliary_a" fullColor>
                            匯入主檔
                        </UpLoadFileButton>
                        <UpLoadFileButton
                            width={120}
                            url={`api/cycleorderimport/upload`}
                            color="auxiliary_a"
                            options={[...Array(12)].map((x, i) => ({ text: `${i + 1}月`, value: i + 1 }))}
                            queryKey={`month`}
                            isAlert={true}
                            fullColor
                        >
                            匯入付款資訊
                        </UpLoadFileButton>
                        <ButtonSelector
                            width={100}
                            color="auxiliary_b"
                            label="匯出"
                            options={daysOption}
                            fullColor
                            handleExcelDownload={handleExcelDownload}
                        />
                    </div>
                </div>
                <div>{isMount && <CycleOrderTable defaultData={sourceData} handleChangePage={handleChangePage} />}</div>
            </Card>
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(CycleOrderList);
