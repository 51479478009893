import React from 'react';
// @material-ui
import { ButtonBase, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const buttonColor = {
    primary: '#601986',
    secondary: '#F086A5',
    auxiliary_a: '#65dcfb',
    auxiliary_b: '#bb8df0',
    auxiliary_c: '#8057F2',
    auxiliary_d: '#8ad987',
};

const Button = ({
    label,
    className,
    fontSize = 16,
    width = 130,
    height = 40,
    color = 'primary',
    fullColor,
    tooltip = { title: '', enterDelay: 500, leaveDelay: 200 },
    handleOnClick: propsHandleOnClick,
    ...props
}) => {
    const classes = makeStyles(() => {
        const bg = { border: `1px solid ${buttonColor[color]}`, color: buttonColor[color] };
        if (buttonColor[color]) {
            if (fullColor) {
                bg.background = buttonColor[color];
                bg.color = `#fff`;
            } else {
                bg.background = `1px solid ${buttonColor[color]}`;
                bg.color = buttonColor[color];
            }
        }
        return {
            buttonBase: {
                fontSize,
                width,
                height,
                borderRadius: height / 2,
                color: '#fff',
                boxShadow: '0px 2px 10px #a7a7a7',
            },
            buttonColor: {
                ...bg,
            },
        };
    })();

    const handleOnClick = async (e) => {
        propsHandleOnClick && propsHandleOnClick(e);
    };

    let btn = (
        <ButtonBase
            className={clsx(classes.buttonBase, classes.buttonColor, className)}
            onClick={handleOnClick}
            {...props}
        >
            {label || ''}
            {props.children || null}
        </ButtonBase>
    );

    if (tooltip) {
        btn = <Tooltip {...tooltip}>{btn}</Tooltip>;
    }

    return <React.Fragment>{btn}</React.Fragment>;
};

export default Button;
