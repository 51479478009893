import React, { useState, useRef, useImperativeHandle } from 'react';
// @material-ui
import { Card, TextInput, Select, FormattedInputs, DatePicker } from '../../../components';
import useCommomFn from '../../../common/useCommomFn';
import _ from 'lodash';

const PaymentCard = ({ sourceData, disabled, optionData, ...props }, ref) => {
    const { paymentTypeOption, invoiceTypeOption, invoiceMethodOption } = optionData;
    const { getValidDateFormatFn, getPadStartFn } = useCommomFn();
    const {
        paymentType = '',
        creditCardNo = '',
        validYear = '',
        validMonth = '',
        confirmCode = '',
        remittanceAccount = '',
        remittanceName = '',
        remittanceTime = '',
        invoiceType = '',
        taxID = '',
        invoiceTitle = '',
        invoiceMethod = '',
        vehicle = '',
        donationCode = '',
        cardRelation = '',
    } = sourceData;

    const [paymentTypeState, setPaymentTypeState] = useState(_.toString(paymentType) || '1');

    const paymentTypeRef = useRef();
    const cCNumberRef = useRef();
    const validDatesRef = useRef();
    const cVVRef = useRef();
    const paymentDateRef = useRef();
    const remittanceLastFiveNumberRef = useRef();
    const remittanceNameRef = useRef();
    const receiptTypeRef = useRef();
    const taxIDNumberRef = useRef();
    const invoiceTitleRef = useRef();
    const receiptModeRef = useRef();
    const carrierNumRef = useRef();
    const loveCodeRef = useRef();
    const cardRelationRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const paymentType = paymentTypeRef.current.value;
            const cCNumber = _.trim(cCNumberRef.current?.value ?? '');
            const validDates = getValidDateFormatFn(validDatesRef.current?.value ?? '');
            const cVV = _.trim(cVVRef.current?.value ?? '');
            const paymentDate = paymentDateRef.current?.value ?? '';
            const remittanceLastFiveNumber = _.trim(remittanceLastFiveNumberRef.current?.value ?? '');
            const remittanceName = remittanceNameRef.current?.value ?? '';
            const receiptType = receiptTypeRef.current.value;
            const taxIDNumber = _.trim(taxIDNumberRef.current?.value ?? '');
            const invoiceTitle = invoiceTitleRef.current?.value ?? '';
            const receiptMode = receiptModeRef.current?.value ?? '';
            const carrierNum = _.trim(carrierNumRef.current?.value ?? '');
            const loveCode = _.trim(loveCodeRef.current.value);
            const cardRel = cardRelationRef.current.value;

            return {
                paymentType,
                creditCardNo: cCNumber,
                validYear: validDates[1] || '',
                validMonth: validDates[0] || '',
                confirmCode: cVV,
                remittanceTime: paymentDate,
                remittanceAccount: remittanceLastFiveNumber,
                remittanceName: remittanceName,
                invoiceType: receiptType,
                taxID: taxIDNumber,
                invoiceTitle: invoiceTitle,
                invoiceMethod: receiptMode,
                vehicle: carrierNum,
                donationCode: loveCode,
                cardRelation: cardRel,
            };
        },
    }));

    const handlePaymentTypeOnChange = (val, e) => {
        setPaymentTypeState(val);
    };

    return (
        <Card>
            <React.Fragment>
                <div>
                    <h4 className="card-title">付款資訊</h4>
                </div>
                <div className="form-flex-row">
                    <Select
                        label="支付方式"
                        name="paymentType"
                        inputRef={paymentTypeRef}
                        sourceData={paymentTypeOption}
                        handleOnChange={handlePaymentTypeOnChange}
                        defaultValue={paymentType}
                        disabled={disabled}
                        noEmptyOption
                        required
                    />
                </div>
                {paymentTypeState === '1' && (
                    <div className="form-flex-row">
                        <FormattedInputs
                            label="信用卡卡號"
                            name="cCNumber"
                            inputRef={cCNumberRef}
                            defaultValue={creditCardNo}
                            disabled={disabled}
                            formatted="creditcard"
                        />
                        <FormattedInputs
                            label="信用卡有效日期"
                            name="validDates"
                            inputRef={validDatesRef}
                            defaultValue={`${getPadStartFn(validMonth, 2)}${validYear}`}
                            disabled={disabled}
                            formatted="validdate"
                        />
                        <FormattedInputs
                            label="確認碼(後3碼)"
                            name="cVV"
                            inputRef={cVVRef}
                            defaultValue={confirmCode}
                            disabled={disabled}
                            formatted="postalcode"
                        />
                    </div>
                )}
                {paymentTypeState === '2' && (
                    <div className="form-flex-row">
                        <DatePicker
                            label="繳款日期"
                            name="paymentDate"
                            inputRef={paymentDateRef}
                            defaultValue={remittanceTime}
                            disabled={disabled}
                        />
                        <FormattedInputs
                            label="匯款帳號後五碼"
                            name="remittanceLastFiveNumber"
                            inputRef={remittanceLastFiveNumberRef}
                            defaultValue={remittanceAccount}
                            disabled={disabled}
                            formatted="atmnumber"
                        />
                        <TextInput
                            label="匯款人姓名"
                            name="remittanceName"
                            inputRef={remittanceNameRef}
                            defaultValue={remittanceName}
                            disabled={disabled}
                        />
                    </div>
                )}
                <div className="form-flex-row">
                    <Select
                        sourceData={invoiceTypeOption}
                        label="發票類型"
                        name="receiptType"
                        inputRef={receiptTypeRef}
                        defaultValue={invoiceType}
                        disabled={disabled}
                        noEmptyOption
                    />
                    <FormattedInputs
                        label="統一編號"
                        name="taxIDNumber"
                        inputRef={taxIDNumberRef}
                        defaultValue={taxID}
                        disabled={disabled}
                    />
                    <TextInput
                        label="發票抬頭"
                        name="invoiceTitle"
                        inputRef={invoiceTitleRef}
                        defaultValue={invoiceTitle}
                        disabled={disabled}
                    />
                </div>
                <div className="form-flex-row">
                    <Select
                        sourceData={invoiceMethodOption}
                        label="發票方式"
                        name="receiptMode"
                        inputRef={receiptModeRef}
                        defaultValue={invoiceMethod}
                        disabled={disabled}
                        noEmptyOption
                    />
                    <FormattedInputs
                        label="載具號碼"
                        name="carrierNum"
                        inputRef={carrierNumRef}
                        defaultValue={vehicle}
                        disabled={disabled}
                    />
                    <FormattedInputs
                        label="發票捐贈碼"
                        name="loveCode"
                        inputRef={loveCodeRef}
                        defaultValue={donationCode}
                        disabled={disabled}
                    />
                </div>
                <div className="form-flex-row">
                    <TextInput
                        label="卡片關係人"
                        name="cardRelation"
                        inputRef={cardRelationRef}
                        defaultValue={cardRelation}
                        disabled={disabled}
                    />
                </div>
            </React.Fragment>
        </Card>
    );
};

export default React.forwardRef(PaymentCard);
