export const localUser = {
    get() {
        return JSON.parse(localStorage.getItem('user'));
    },
    set(user) {
        return localStorage.setItem('user', JSON.stringify(user))
    },
    hasData() {
        return localStorage.getItem('user');
    },
    updateAttr(attr, value) {
        let user = this.get();
        user[attr] = value
        this.set(user)
    },
    logout() {
        return localStorage.removeItem('user')
    }
}
