import React, { useRef, useEffect, useCallback, useState } from 'react';
import CycleOrderExportCard from '../../order/download/components/CycleOrderExportCard';
import MenberTransferTable from './component/MenberTransferTable';
import _ from 'lodash';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';
import { Button, Card } from '../../components';
import useAxiosFunction from '../../apis/useAxiosFn';
import { useHistory } from 'react-router-dom';
import fileDownload from 'js-file-download';
import moment from 'moment';

const MemberTransferList = ({ actions, ...props }) => {
    const history = useHistory();
    const Axios = useCallback(useAxiosFunction(actions), []);
    const [options, setOptions] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const cycleOrderExportCardRef = useRef();
    const memberConversionTableRef = useRef();

    const doSearchApi = useCallback(async () => {
        let source = cycleOrderExportCardRef.current.getResult();
        source.startDate = moment().format('YYYY-MM-DD');
        source.endDate = moment().format('YYYY-MM-DD');
        const apiData = await Axios.post('/api/members', source, {});
        if (apiData) setSourceData([...apiData]);
    }, [Axios]);

    const doTransferMemberToiCoachApi = useCallback(async () => {
        const source = memberConversionTableRef.current.getResult();
        if (_.isEmpty(source)) return;
        const apiData = await Axios.put('/api/members/transfer', source);
        if (apiData) {
            alert('會籍轉移成功 即將刷新頁面');
            doSearchApi();
        }
    }, [Axios, doSearchApi]);

    const initialValue = useCallback(async () => {
        const params = { hasAll: true };
        const apiShopOptions = await Axios.get('/api/shopoptions/options', { params });
        const apiShipDaysOptions = await Axios.get('/api/cycleorderoptions/shipCycleSearchBar', { params });
        const apiPlatformType = await Axios.get('/api/cycleorderoptions/systemType', { params: { hasAll: false } });
        const apiOptions = {
            shopOptions: apiShopOptions,
            shipDaysOptions: apiShipDaysOptions,
            platformTypeOptions: apiPlatformType,
        };
        setOptions(apiOptions);
        if (history.location.state) {
            doSearchApi();
            history.replace(history.location.pathname, undefined);
        }
    }, [Axios, history, doSearchApi]);

    const handleSearchOnClick = (e) => {
        doSearchApi();
    };

    const handleDownloadExcelOnclick = async (e) => {
        e.preventDefault();
        const url = `/api/cycleorderexport/traded/output.xlsx`;
        let value = memberConversionTableRef.current.getResult();
        if (_.isEmpty(value)) return;
        const ariData = await Axios.post(url, [...value], { responseType: 'blob' });
        fileDownload(ariData.data, ariData.filename);
    };

    const handleTransferMemberOnclick = async (e) => {
        const platformType = cycleOrderExportCardRef.current.getResult()?.platformType ?? '';
        if (platformType === '2' || !platformType) {
            alert('無法轉移總部以外的會員，系統別請選擇總部在搜尋');
            return;
        }
        const isYes = window.confirm('轉移至iCoach後就無法復原，確認要轉移？');
        if (isYes) doTransferMemberToiCoachApi();
    };

    useEffect(() => {
        initialValue();
    }, [initialValue]);

    return (
        <React.Fragment>
            {!_.isEmpty(options) && (
                <CycleOrderExportCard
                    ref={cycleOrderExportCardRef}
                    defaultData={{ ...(history.location?.state ?? { shopID: '0' }) }}
                    options={options}
                />
            )}
            <div className="view-normal-width container-root container-disable-gutters">
                <div className="flex flex-jc-center button-root">
                    <Button label="搜尋" color="auxiliary_d" fullColor handleOnClick={handleSearchOnClick} />
                </div>
            </div>
            <Card>
                <MenberTransferTable defaultData={sourceData} ref={memberConversionTableRef} />
            </Card>
            <div className="view-normal-width container-root container-disable-gutters">
                <div className="flex flex-jc-center button-root">
                    <Button label="下載" color="auxiliary_d" fullColor handleOnClick={handleDownloadExcelOnclick} />
                    <Button
                        label="移轉至iCoach"
                        color="auxiliary_d"
                        fullColor
                        handleOnClick={handleTransferMemberOnclick}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(MemberTransferList);
