import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../../redux/dispatch';

// material ui
import { ButtonBase } from '@material-ui/core';
import Tables from '../../../components/Tables';
import { v4 as uuid } from 'uuid';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';
import useAxiosFunction from '../../../apis/useAxiosFn';

//循環訂單清單列表
export const circleOrderHeaderLabel = [
    {
        labelKey: 'customOrderID',
        label: '編號',
        minWidth: 120,
        format: (value, selfData) => {
            const { status: orderStatus } = selfData.payment;
            let propsState = {
                className: 'underline',
                component: NavLink,
                to: {
                    pathname: `/order/cycleorderedit/${value}`,
                    state: {
                        curData: { ...selfData },
                        orderStatus,
                    },
                },
            };
            return <ButtonBase {...propsState}>{value}</ButtonBase>;
        },
    },
    {
        labelKey: 'store',
        label: '所屬店舖',
        minWidth: 140,
    },
    {
        labelKey: 'memberID',
        label: '會員編號',
        minWidth: 140,
    },
    {
        labelKey: 'memberInfo',
        label: '會員資訊',
        minWidth: 140,
        format: (value, selfData) => {
            const Tag = (
                <React.Fragment>
                    <div>{selfData['memberName']}</div>
                    <div>{selfData['memberPhone']}</div>
                </React.Fragment>
            );
            return Tag;
        },
    },
    {
        labelKey: 'detail',
        label: '訂單內容',
        minWidth: 200,
        format: (value, selfData = []) => {
            const estimatedShipDay = selfData['estimatedShipDay'] || '';
            const view = selfData[value].map((target) => {
                const tag = (
                    <div key={uuid()}>
                        {target['productName']} x {target['amount']}
                    </div>
                );
                return tag;
            });
            return (
                <React.Fragment>
                    {estimatedShipDay && <span className="table-title-shipday">出貨週期:{estimatedShipDay}</span>}
                    {view}
                </React.Fragment>
            );
        },
    },
    {
        labelKey: 'payment',
        label: '訂單金額',
        minWidth: 120,
        align: 'right',
        format: (value, selfData, sourceParams) => {
            const { statusOption } = sourceParams;
            const status = _.toString(selfData[value]['status']);
            const money = selfData[value]['money'];
            const source = statusOption.find((target) => _.toString(target.value) === _.toString(status));
            // api/cycleorderoptions/status  5暫停 6退訂
            const bgColor = status === '5' ? 'primary-bg' : status === '6' ? 'secondary-bg' : '';
            let view = !(status === '5' || status === '6') ? (
                money.toLocaleString('en-US')
            ) : (
                <div className={clsx('curves-btn-small', bgColor, 'ban-select')}>
                    <span>{source?.text ?? ''}</span>
                </div>
            );
            return view;
        },
    },
];

const CycleOrderTable = ({ defaultData = {}, actions, ...props }) => {
    const Axios = useCallback(useAxiosFunction(actions), []);
    const { pageIndex = 1, totalPage, list } = defaultData;
    const { handleChangePage } = props;
    const [isMount, setIsMount] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        (async () => {
            const apiOption = await Axios.get(`api/cycleorderoptions/status`);
            if (apiOption) setOptions([...apiOption]);
            setIsMount(true);
        })();
        return () => {
            setIsMount(false);
        };
    }, [Axios]);

    return (
        <React.Fragment>
            {isMount && (
                <Tables isPagination handleChangePage={handleChangePage} page={pageIndex} count={totalPage} >
                    <Tables.TableRowHeader headerLabel={circleOrderHeaderLabel} />
                    <Tables.TableRowBody
                        headerLabel={circleOrderHeaderLabel}
                        sourceData={list}
                        statusOption={options}
                    />
                </Tables>
            )}
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(CycleOrderTable);
