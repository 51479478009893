import React from 'react';
import Modal from 'react-modal';
import clsx from 'clsx';
import _ from 'lodash';

Modal.setAppElement(document.body);

const Backdrop = ({
    isOpen = false,
    className = '',
    onAfterOpen = () => {},
    onRequestClose = () => {},
    closeTimeoutMS = 300,
    children = React.Component,
    shouldCloseOnOverlayClick = false,
    ...props
}) => {
    const defaultProps = {
        isOpen,
        portalClassName: 'modal-protal',
        className: clsx('dialog', className),
        closeTimeoutMS,
        ariaHideApp: false,
        style: { overlay: { background: `rgba(0,0,0,0.5)` } },
        onAfterOpen: _.isFunction(onAfterOpen) ? onAfterOpen : () => {},
        onRequestClose: _.isFunction(onRequestClose) ? onRequestClose : () => {},
        shouldCloseOnOverlayClick: true,
    };

    return <Modal {...defaultProps}>{children}</Modal>;
};

export default Backdrop;
