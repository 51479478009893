import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import clsx from 'clsx';

// @material-ui
// import { Box, List } from '@material-ui/core';

// import Axios from 'axios';
// import routes from '../home/routes';
// import useCommomFn from '../common/useCommomFn';
// import useAxiosFunction from '../apis/useAxiosFn';


const ProductStock = () => {
    // const initialValue = useCallback(async () => {
        // const menus = getMenuRoutesFn(routes.children);
        // setMenu(menus);
        // const result = await AxiosApi.get(`/api/parameters/systemsetting/version`);
        // if (result && result.length > 0) {
        //     setVersion(result[0].value);
        // }
    // }, []);


    // useEffect(() => {
    //     initialValue();
    // }, [initialValue]);

    return (
        <div>ProductStock</div>
    );
};

export default ProductStock;
