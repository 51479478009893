import React, { useState } from 'react';
import _ from 'lodash';
import Tables from '../../components/Tables';
import { padStartFn, formatCurrencyFn } from '../../../scripts/common/common';
import { Edit, DeleteForever, AddCircle } from '@material-ui/icons';
import { IconButton, Tabs, Tab } from '@material-ui/core';

const discountViewFn = (num) => {
    let refNum = parseInt(num * 100, 10);
    const quotient = !!(refNum % 10);
    const remainder = !!parseInt(refNum / 10);

    if (!quotient && remainder) refNum = _.replace(refNum, 0, '') + '折';
    else if (quotient && !remainder) refNum = padStartFn(refNum, 2) + '折';
    else if (quotient && remainder) refNum = refNum + '折';
    return !!refNum && refNum;
};

//循環訂單清單列表
export const productsTableLabel = [
    {
        labelKey: 'sku',
        label: 'SKU',
    },
    {
        labelKey: 'name',
        label: '品名',
    },
    {
        labelKey: 'sellPrice',
        label: '售價',
        align: 'right',
        format: (value) => formatCurrencyFn(value),
    },
    {
        labelKey: 'discountedPrice',
        label: '折扣價',
        align: 'right',
        format: (value) => (value ? formatCurrencyFn(value) : '-'),
    },
    {
        labelKey: 'memberPrice',
        label: '會員價',
        align: 'right',
        format: (value) => (value ? formatCurrencyFn(value) : '-'),
    },
    {
        labelKey: 'discountedPrice',
        label: '顯示售價',
        align: 'center',
        format: (value, defaultData) => {
            const discountRate = discountViewFn(defaultData['discountRate']);
            const RateComponent = value ? (
                <div>
                    <span className="product-list-sell-price">
                        {!value ? formatCurrencyFn(defaultData['sellPrice']) : formatCurrencyFn(value)}
                    </span>
                    <br />
                    {discountRate && (
                        <span className="product-list-discount-view">
                            {discountViewFn(defaultData['discountRate'])}
                        </span>
                    )}
                </div>
            ) : (
                <div>-</div>
            );

            return RateComponent;
        },
    },
    {
        labelKey: '',
        label: ({ handleAddCategories }) => (
            <IconButton onClick={handleAddCategories}>
                <AddCircle className="add-icon" tooltip="新增子分類" />
            </IconButton>
        ),
        align: 'right',
        format: (value, defaultData, { handleEditClick, handleDeleteClick }) => (
            <div className="product-list-buttons flex">
                <IconButton onClick={handleEditClick(defaultData.id)}>
                    <Edit className="edit-icon" tooltip="編輯" />
                </IconButton>
                <IconButton onClick={handleDeleteClick(defaultData.id)}>
                    <DeleteForever className="delete-icon" tooltip="刪除" />
                </IconButton>
            </div>
        ),
    },
];

const ProductTable = ({ defaultData = {}, handleChangePage }) => {
    const { pageIndex = 1, totalPage = 1, list = [] } = defaultData;
    const [tabsIndex, setTabsIndex] = useState(1);

    const handleAddCategories = (e) => {
        console.log('handleAddCategories');
    };

    const handleEditClick = (productId) => (e) => {
        console.log('handleEditClick productId:', productId);
    };

    const handleDeleteClick = (productId) => (e) => {
        console.log('handleDeleteClick productId:', productId);
    };

    const handleTabsOnChange = (e, value) => {
        setTabsIndex(value);
    };

    return (
        <React.Fragment>
            <Tabs
                onChange={handleTabsOnChange}
                value={tabsIndex}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
            >
                {_.map(Array.from(new Array(4)), (target, id) => (
                    <Tab key={id} label={`Sazike${id}`} value={id} />
                ))}
            </Tabs>
            <Tables isPagination handleChangePage={handleChangePage} page={pageIndex} count={totalPage}>
                <Tables.TableRowHeader headerLabel={productsTableLabel} handleAddCategories={handleAddCategories} />
                <Tables.TableRowBody
                    headerLabel={productsTableLabel}
                    sourceData={list}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                />
            </Tables>
        </React.Fragment>
    );
};

export default ProductTable;
