import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/dispatch';

import { useHistory } from 'react-router-dom';
import { localUser } from '../util';
// @material-ui
import { Box, TextField, ButtonBase, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
// img
import Logo from '../../images/curves.svg';
import Arrow from '../../images/arrow.svg';
import useAxiosFunction from '../apis/useAxiosFn';

const useStyles = makeStyles(() => {
    const circleWH = 100;
    return {
        buttonStyle: {
            fontWeight: 700,
            backgroundColor: '#ffffff',
            color: '#601986',
            width: circleWH,
            height: circleWH,
            border: '2px solid #601986',
            borderRadius: '50%',
            '&::after': {
                position: 'absolute',
                content: `url("${Arrow}")`,
                top: circleWH / 2,
                left: circleWH / 2,
            },
        },
    };
});

const Login = ({ actions }) => {
    const Axios = useAxiosFunction(actions);
    const history = useHistory();
    const classes = useStyles();
    const usernameRef = createRef();
    const passwordRef = createRef();

    const handleLoginApi = (e) => {
        e.preventDefault();
        getResult();
    };

    const getResult = () => {
        const usr = usernameRef.current.value;
        const pwd = passwordRef.current.value;
        if (usr === '' || pwd === '') {
            alert('帳號或密碼不得為空值');
            return;
        }
        doApi(usr, pwd);
    };

    const doApi = async (usr, pwd) => {
        const apiData = await Axios.post('api/login', { username: usr, password: pwd });
        if (apiData) {
            localUser.set(apiData);
            history.push('/');
        }
    };
    return (
        <div className="container-root">
            <div className="login-header">
                <img src={Logo} className="login-logo login-image" alt="curves" />
            </div>
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <div className="container-root container-width-sm">
                    <form className="login-panel" onSubmit={handleLoginApi}>
                        <TextField
                            className={clsx('ban-select')}
                            fullWidth
                            label="帳號"
                            margin="normal"
                            name="username"
                            type="string"
                            inputRef={usernameRef}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            className={clsx('ban-select')}
                            fullWidth
                            label="密碼"
                            margin="normal"
                            name="password"
                            type="password"
                            inputRef={passwordRef}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Box mt={5}>
                            <ButtonBase className={classes.buttonStyle} type="submit">
                                登入
                            </ButtonBase>
                        </Box>
                    </form>
                </div>
            </Box>
            <div className="login-footer">
                <div className="login-copywrite ban-select">
                    <span>版權所有 © 2021 Curves 可爾姿女性30分鐘環狀運動</span>
                    <br />
                    <span>v 0.3.0</span>
                </div>
            </div>
        </div>
    );
};

export default connect(null, mapDispatchToProps('showLoading'))(Login);
