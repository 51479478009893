import React, { useState, useEffect, useMemo } from 'react';
import CategoriesTable from './Components/CategoriesTable';


const ProductCategoryList = () => {
    const [sourceData, setSourceData] = useState([]);
    const [isMount, setIsMount] = useState(false);

    const memoCategoriesTable = useMemo(() => isMount && <CategoriesTable defaultData={sourceData} />, [
        sourceData,
        isMount,
    ]);

    const initFetchAllData = async () => {
        setSourceData([...apiData]);
        setIsMount(true);
    };

    useEffect(() => {
        initFetchAllData();
    }, []);

    return (
        <div className="category">
            {memoCategoriesTable}
        </div>
    );
};

const apiData = [
    {
        name: '一般商品',
        sort: 0,
        id: 1,
        parentId: 0,
        children: [2, 4, 5],
        nodes: '1',
        productNums: 0,
    },
    {
        name: 'Gear商品',
        sort: 0,
        id: 2,
        parentId: 1,
        children: [3],
        nodes: '1-2',
        productNums: 0,
    },
    {
        name: '上衣',
        sort: 0,
        id: 3,
        parentId: 2,
        children: null,
        nodes: '1-2-3',
        productNums: 10,
    },
    {
        name: '零售商品',
        sort: 0,
        id: 4,
        parentId: 1,
        children: [6, 7],
        nodes: '1-4',
        productNums: 0,
    },
    {
        name: '健康商品',
        sort: 0,
        id: 5,
        parentId: 1,
        children: null,
        nodes: '1-5',
        productNums: 0,
    },
    {
        name: '最新預購商品',
        sort: 0,
        id: 6,
        parentId: 4,
        children: null,
        nodes: '1-4-6',
        productNums: 0,
    },
    {
        name: '熱銷主打⚡滿件再7折',
        sort: 0,
        id: 7,
        parentId: 4,
        children: null,
        nodes: '1-4-7',
        productNums: 0,
    },
    {
        name: '線上商品',
        sort: 0,
        id: 8,
        parentId: 0,
        children: [9],
        nodes: '8',
        productNums: 0,
    },
    {
        name: '椅子',
        sort: 0,
        id: 9,
        parentId: 8,
        children: null,
        nodes: '8-9',
        productNums: 0,
    },
];

export default ProductCategoryList;
