import React from 'react';
import Tables from '../../../components/Tables';
import { optionMapFn } from '../../../common/common';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

const formTableHeader = [
    {
        labelKey: 'orderNo',
        label: '編號',
        minWidth: '120px',
        align: 'left',
    },
    {
        labelKey: 'orderDate',
        label: '日期',
        minWidth: '140px',
        align: 'left',
        format: (curVal, selfData) => {
            return <div>{moment(selfData['orderDate']).format('YYYY/MM/DD')}</div>;
        },
    },
    {
        labelKey: 'orderStatus',
        label: '狀態',
        minWidth: '140px',
        align: 'left',
        format: (curVal, selfData, { optionData }) => {
            const orderStatus = optionMapFn(optionData, selfData['orderStatus']);
            return <div>{orderStatus}</div>;
        },
    },
    {
        labelKey: 'Content',
        label: '內容',
        minWidth: '250px',
        align: 'left',
        format: (curVal, selfData) => {
            let sheet = _.map(selfData['products'], (target) => (
                <div key={uuid()}>
                    {target['productName']} x {target['quantity']}
                </div>
            ));
            sheet = <React.Fragment>{sheet}</React.Fragment>;
            return sheet;
        },
    },
];

const OrderRecordTable = ({ sourceData, optionData }, ref) => {
    const { orderStatusOption } = optionData;
    const { orders } = sourceData;
    return (
        <React.Fragment>
            <Tables>
                <Tables.TableRowHeader headerLabel={formTableHeader} />
                <Tables.TableRowBody headerLabel={formTableHeader} sourceData={orders} optionData={orderStatusOption} />
            </Tables>
        </React.Fragment>
    );
};

export default React.forwardRef(OrderRecordTable);
