import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @material-ui
import { makeStyles, ListItem, Button, Collapse, List } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Dot from '../../../images/dot.svg';

const useStyles = makeStyles(() => {
    return {
        item: {
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0,
        },
        title: {
            marginRight: 'auto',
            '&>.icon-margin-right': {
                marginRight: '10px',
            },
            '&>i svg': {
                fontSize: 'inherit',
            },
        },
        button: {
            justifyContent: 'flex-start',
            letterSpacing: 0,
            padding: '12px 24px',
            textTransform: 'none',
            width: '100%',
            borderRadius: 0,
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, .1)',
            },
        },
        subButton: {
            justifyContent: 'flex-start',
            width: '100%',
        },
        nested: {
            padding: 0,
        },
        dot: {
            padding: '0 20px 0 5px',
        },
    };
});

const LabelIcon = ({ text, title, iconClass, iconDom: IconSvg }) => {
    let Icon = () => (
        <React.Fragment>
            {IconSvg ? (
                <i className={`icofont icon-margin-right`}>
                    <IconSvg />
                </i>
            ) : (
                <i className={`icofont icofont-${iconClass} icon-margin-right`}></i>
            )}
        </React.Fragment>
    );
    return (
        <span className={title}>
            <Icon />
            {text}
        </span>
    );
};

const NavSubject = ({ sourceData, isOpen = false, ...props }) => {
    let { id, label, iconClass, content } = sourceData;
    const classes = useStyles();
    return (
        <React.Fragment>
            {(content?.length ?? 0) === 0 ? (
                <ListItem className={classes.item} disableGutters key={id}>
                    <Button
                        className={classes.button}
                        {...(sourceData.href ? { component: RouterLink, to: sourceData.href } : {})}
                    >
                        <LabelIcon
                            text={label}
                            title={classes.title}
                            iconClass={iconClass}
                            iconDom={sourceData.iconDom}
                        />
                    </Button>
                </ListItem>
            ) : (
                <React.Fragment>
                    <ListItem className={classes.item} disableGutters key={id}>
                        <Button className={classes.button} onClick={props.handleCollapseChange(id)}>
                            <LabelIcon
                                text={label}
                                title={classes.title}
                                iconClass={iconClass}
                                iconDom={sourceData.iconDom}
                            />
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </Button>
                    </ListItem>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {content.map((target, id) => {
                                return (
                                    <ListItem key={id}>
                                        <Button className={classes.subButton} component={RouterLink} to={target.href}>
                                            <span className={classes.subTitle}>
                                                <img src={Dot} alt="dot" className={classes.dot} />
                                                {target.label}
                                            </span>
                                        </Button>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default NavSubject;
