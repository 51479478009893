import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import TopBar from '../topbar';
import Nav from '../nav';
import routesMap from './routes';
import useCommomFn from '../common/useCommomFn';
import _ from 'lodash';

const Home = () => {
    const { getRoutesMapComponentFn } = useCommomFn();
    const routes = useMemo(() => {
        return <Switch>{_.flattenDeep(getRoutesMapComponentFn(PrivateRoute)(routesMap))}</Switch>;
    }, [getRoutesMapComponentFn]);
    
    return (
        <React.Fragment>
            <TopBar />
            <Nav />
            <div className="main-body">
                <div className="main-outer">
                    <div className="main-inner">{routes}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Home;
