import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TextInput } from '.';

const AutocompleteInput = ({
    label,
    name,
    sourceData = [],
    handleOnInputChange,
    inputRef,
    noOptionsText,
    defaultValue,
    width = 220,
    disabled,
    required,
    ...props
}) => {
    const clsses = makeStyles(() => ({
        textWidth: {
            width: width,
        },
    }))();
    const defaultProps = {
        noOptionsText: '查無資料',
        clearText: '清空',
        options: sourceData,
        disableClearable: !!props.disableClearable,
        clearOnEscape: !!props.clearOnEscape,
        getOptionLabel: (option) => (option.text === '' ? undefined : option.text),
        onInputChange: (event, value, reason) => {
            handleOnInputChange && handleOnInputChange(value, event);
        },
        getOptionSelected: (option, value) => {
            return option?.id === value?.id || option?.name.toLowerCase() === value?.name.toLowerCase();
        },
        disabled: disabled,
        required: required,
    };

    if (!(defaultValue === '' || defaultValue === null || defaultValue === undefined)) {
        defaultProps.defaultValue = defaultValue;
    } else {
        defaultProps.defaultValue = null;
    }

    return (
        <React.Fragment>
            <Autocomplete
                {...defaultProps}
                className={clsses.textWidth}
                renderInput={(params) => <TextInput {...params} name={name} label={label} inputRef={inputRef} />}
            />
        </React.Fragment>
    );
};

export default AutocompleteInput;
