import React from 'react';
import clsx from 'clsx';

const Card = ({ className, children, noWidth, ...props }) => {
    const style = ['paper-root', 'paper-elevation', 'card-root'];
    const extraConfigure = {
        'view-normal-width': !noWidth,
    };
    return <div className={clsx(style, extraConfigure, className)}>{children}</div>;
};

export default Card;
