import React from 'react';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';

const optionMapFn = (source, value, { option = 'value', view = 'text' } = {}) => {
    let val = '';
    const findSource = _.find(source, (target) => _.toString(target[option]) === _.toString(value));
    if (_.isEmpty(findSource)) return '';
    if (_.isString(view)) val = !!_.toString(findSource[view]) ? findSource[view] : '';
    else if (_.isArray(view)) val = [...findSource];
    else if (_.isObject(view)) val = { ...findSource };
    return val;
};

// 格式化信用卡卡號
const cardNumberFormatFn = (value) => {
    return value.replace(/[^\d|^*]/g, '');
};

// 格式化有效日期  XX 月/ XX 年
const validDateFormatFn = (value) => {
    let arr = [];
    let myRegexp = /\d+/g;
    let result;
    while ((result = myRegexp.exec(value))) {
        arr.push(result[0]); // 將找到聯合數字加入該陣列
    }
    return arr;
};

// 前方補0
const padStartFn = (valus, num) => {
    let target = _.toString(valus);
    if (!target) return target;
    target = _.padStart(target, num, '0');
    return target;
};

// 後方補0
const padEndFn = (valus, num) => {
    let target = _.toString(valus);
    if (!target) return target;
    target = _.padEnd(target, num, '0');
    return target;
};

const formatCurrencyFn = (target, { symbol = '', point = 0 } = {}) => {
    let num = parseFloat(target, 10);
    if (_.isNaN(num)) return '';
    num = num.toLocaleString('en-US');
    if (point) num = num.toFixed(point);
    if (symbol) num = symbol + num;
    return num;
};

// 擷取資料
const resultFn = (instanceType, source, fn) => {
    if (_.isEmpty(source)) return instanceType;
    const { result } = source;
    if (source.success) {
        if (_.isArray(instanceType)) instanceType = [].concat(instanceType, result);
        if (_.isObject(instanceType)) instanceType = Object.assign(instanceType, result);
    } else {
        _.isFunction(fn) && fn(instanceType, source);
    }
    return _.isFunction(fn) ? fn(instanceType, source) : instanceType;
};

// 合併物件
const intersectionFn = (newObject, originalObject) => {
    let target = {};
    for (const i in originalObject) {
        if (originalObject.hasOwnProperty(i)) {
            target[i] = originalObject[i];
        }
    }
    for (const i in originalObject) {
        if (newObject.hasOwnProperty(i)) {
            target[i] = newObject[i];
        }
    }
    return target;
};

//匹配 autocomplete 的 option
const matchSorterSingleKeysFn = (options, value, keys = 'text') => {
    return matchSorter(options, value, { keys: [keys] })[0]?.value ?? '';
};

// switch routes use
const routesMapComponentFn = (Component) => (
    { id, children, href: parentHref, component, ...rest },
    propsHref = ''
) => {
    let parentNodes = [];
    let childNodes = [];
    propsHref += parentHref;
    if (component) {
        const nodeParent = <Component key={id} path={`${propsHref || '/'}`} component={component} {...rest} />;
        parentNodes.push(nodeParent);
    }
    if (children) {
        childNodes = _.map(children, (target) => {
            const { component: childComponent, id: childId, href: childHref, children: childrens, ...others } = target;
            let childAry = [];
            if (childComponent) {
                const nodeChild = (
                    <Component key={childId} path={`${propsHref}${childHref}`} component={childComponent} {...others} />
                );
                childAry.push(nodeChild);
            }
            if (childrens) childAry.push(routesMapComponentFn(Component)(target, propsHref));
            return childAry;
        });
    }
    return [].concat.apply(parentNodes, childNodes);
};

// Nav menu
const menuRoutesFn = (source, href) => {
    href = href || '';
    let items = _.reduce(
        source,
        (orign, target) => {
            let base = [...orign];
            let item = {
                id: target.id,
                label: target.text,
                iconClass: target.className,
                href: href + target.href,
                iconDom: target?.iconDom ?? undefined,
            };
            if (target.children) item.content = menuRoutesFn(target.children, target.href);
            if (!target.hidden) base.push(item);
            return base;
        },
        []
    );
    return items;
};

export {
    optionMapFn,
    cardNumberFormatFn,
    validDateFormatFn,
    padStartFn,
    padEndFn,
    resultFn,
    intersectionFn,
    matchSorterSingleKeysFn,
    routesMapComponentFn,
    menuRoutesFn,
    formatCurrencyFn,
};
