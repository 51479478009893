import React, { useRef, useImperativeHandle } from 'react';
import { Card, Select, TextInput, DatePicker, Button } from '../../../components';
import { shipmentCycleDayMap } from '../staticData';
import ProductsTable from './ProductsTable';

const ProductShipmentCard = ({ disabled, sourceData, optionData }, ref) => {
    const {
        deliveryMonth = '',
        deliveryCycle = '',
        products = [],
        singleBoxDelivery = '0',
        doubleBoxDelivery = '0',
        bacteriaMultiDistribution = '0',
        shippingInstructions = '',
        notes = '',
    } = sourceData;
    const { productOption } = optionData;
    const shipmentMonthRef = useRef();
    const shipmentCycleDayRef = useRef();
    const oddDeliveryRef = useRef();
    const evenDeliveryRef = useRef();
    const vitDeliveryRef = useRef();
    const shippingInstructionsRef = useRef();
    const notesRef = useRef();
    const productTableRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const shipmentMonth = shipmentMonthRef.current.value;
            const shipmentCycleDay = shipmentCycleDayRef.current.value;
            const oddDeliv = oddDeliveryRef.current.value;
            const evenDeliv = evenDeliveryRef.current.value;
            const vitDeliv = vitDeliveryRef.current.value;
            const productTable = productTableRef.current.getResult();
            const instructions = shippingInstructionsRef.current.value;
            const notesValue = notesRef.current.value;
            return {
                deliveryMonth: shipmentMonth,
                deliveryCycle: shipmentCycleDay,
                singleBoxDelivery: oddDeliv,
                doubleBoxDelivery: evenDeliv,
                bacteriaMultiDistribution: vitDeliv,
                products: [...productTable],
                shippingInstructions: instructions,
                notes: notesValue,
            };
        },
    }));

    const handleAddOnClick = (e) => {
        productTableRef.current.setData();
    };

    return (
        <Card>
            <div>
                <h4 className="card-title">商品出貨</h4>
            </div>
            <div className="form-flex-al-center">
                <div className="spacing-4">
                    <DatePicker
                        label="首次出貨月份"
                        name="shipmentMonth"
                        inputRef={shipmentMonthRef}
                        defaultValue={deliveryMonth}
                        format="yyyy/MM"
                        disabled={disabled}
                    />
                    <Select
                        label="出貨週期"
                        name="shipmentCycleDay"
                        inputRef={shipmentCycleDayRef}
                        sourceData={shipmentCycleDayMap}
                        defaultValue={deliveryCycle}
                        disabled={disabled}
                        noEmptyOption
                    />
                </div>
                {!disabled && (
                    <Button
                        fontSize={16}
                        width={120}
                        handleOnClick={handleAddOnClick}
                        disabled={disabled}
                        color="auxiliary_a"
                        fullColor
                    >
                        新增
                    </Button>
                )}
            </div>
            <div className="form-flex-jc-center">
                <ProductsTable
                    sourceData={products}
                    productItem={productOption}
                    ref={productTableRef}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <TextInput
                    label="單盒配送箱"
                    name="oddDelivery"
                    inputRef={oddDeliveryRef}
                    defaultValue={singleBoxDelivery?.toString()}
                    disabled={disabled}
                />
                <TextInput
                    label="雙盒配送箱"
                    name="evenDelivery"
                    inputRef={evenDeliveryRef}
                    defaultValue={doubleBoxDelivery?.toString()}
                    disabled={disabled}
                />
                <TextInput
                    label="益起動配送箱"
                    name="vitDelivery"
                    inputRef={vitDeliveryRef}
                    defaultValue={bacteriaMultiDistribution?.toString()}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <TextInput
                    label="備註1(出貨須知)"
                    name="shippingInstructions"
                    fullWidth
                    inputRef={shippingInstructionsRef}
                    defaultValue={shippingInstructions}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <TextInput
                    label="備註2(出貨須知)"
                    name="notes"
                    fullWidth
                    inputRef={notesRef}
                    defaultValue={notes}
                    disabled={disabled}
                />
            </div>
        </Card>
    );
};

export default React.forwardRef(ProductShipmentCard);
