import React, { useImperativeHandle, useRef } from 'react';
import { Card, TextInput, DatePicker, FormattedInputs } from '../../../components';

const MemberCard = ({ sourceData, disabled, ...props }, ref) => {
    const {
        address = '',
        birthday = '',
        id = '',
        identiferID = '',
        mail = '',
        name = '',
        number = '',
        phone = '',
        postCode = '',
        recipientName = '',
        recipientPhone = '',
    } = sourceData;

    const memberRef = useRef();
    const birthRef = useRef();
    const memberIdRef = useRef();
    const idNoRef = useRef();
    const emailRef = useRef();
    const telRef = useRef();
    const phoneRef = useRef();
    const contactTelRef = useRef();
    const postalcodeRef = useRef();
    const contactAddressRef = useRef();
    const stakeholdersRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const member = memberRef.current.value.trim();
            const birth = birthRef.current.value.trim();
            const memberId = memberIdRef.current.value.trim();
            const idNo = idNoRef.current.value.trim();
            const email = emailRef.current.value.trim();
            const tel = telRef.current.value.trim();
            const phone = phoneRef.current.value.trim();
            const contactTel = contactTelRef.current.value.trim();
            const postalcode = postalcodeRef.current.value.trim();
            const contactAddress = contactAddressRef.current.value.trim();
            const stakeholders = stakeholdersRef.current.value.trim();

            return {
                name: member,
                birthday: birth,
                id: memberId,
                identiferID: idNo,
                mail: email,
                number: tel,
                phone,
                recipientPhone: contactTel,
                postCode: postalcode,
                address: contactAddress,
                recipientName: stakeholders,
            };
        },
    }));

    return (
        <Card>
            <div>
                <h4 className="card-title">會員資訊</h4>
            </div>
            <div className="form-flex-row">
                <TextInput label="姓名" name="member" inputRef={memberRef} defaultValue={name} disabled={disabled} />
                <DatePicker
                    label="生日"
                    inputRef={birthRef}
                    defaultValue={birthday}
                    disableFuture
                    disabled={disabled}
                />
                <FormattedInputs
                    label="會員編號"
                    name="memberId"
                    inputRef={memberIdRef}
                    defaultValue={id}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <FormattedInputs
                    label="身份證字號(選填)"
                    name="idNo"
                    inputRef={idNoRef}
                    defaultValue={identiferID}
                    formatted="idnumber"
                    disabled={disabled}
                />
                <TextInput
                    label="電子郵件(選填)"
                    name="email"
                    type="email"
                    inputRef={emailRef}
                    width={535}
                    defaultValue={mail}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <FormattedInputs
                    label="電話"
                    name="tel"
                    inputRef={telRef}
                    defaultValue={number}
                    disabled={disabled}
                    formatted="tel"
                />
                <FormattedInputs
                    label="手機"
                    name="phone"
                    inputRef={phoneRef}
                    defaultValue={phone}
                    disabled={disabled}
                    formatted="phone"
                />
                <FormattedInputs
                    label="收件人電話"
                    name="contactTel"
                    inputRef={contactTelRef}
                    defaultValue={recipientPhone}
                    disabled={disabled}
                    formatted="tel"
                />
            </div>
            <div className="form-flex-row">
                <FormattedInputs
                    label="郵遞區域"
                    name="postalcode"
                    inputRef={postalcodeRef}
                    defaultValue={postCode}
                    disabled={disabled}
                    formatted="postalcode"
                />
                <TextInput
                    label="收件人地址"
                    name="contactAddress"
                    inputRef={contactAddressRef}
                    width={535}
                    defaultValue={address}
                    disabled={disabled}
                />
            </div>
            <div className="form-flex-row">
                <TextInput
                    label="收件人姓名"
                    name="stakeholders"
                    inputRef={stakeholdersRef}
                    defaultValue={recipientName}
                    disabled={disabled}
                />
            </div>
        </Card>
    );
};

export default React.forwardRef(MemberCard);
