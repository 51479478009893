import Axios from 'axios';
import _ from 'lodash';

export const AxiosGet = (action = {}, { query }) => async (url, data, config, { ...eles }) => {
    const { callbackFn, isBack, isQueryString, isInitial } = eles;
    data = query.preQueryString(data, { isBack, isQueryString, isInitial });
    const params = {
        url,
        data,
        config,
        isQueryString,
        query,
    };
    return await AxiosApi('get', params, { callbackFn }, action);
};

export const AxiosPost = (action = {}, { query }) => async (url, data, config, { ...eles }) => {
    const { callbackFn, isBack, isQueryString, isInitial } = eles;
    data = query.preQueryString(data, { isBack, isQueryString, isInitial });
    const params = {
        url,
        data,
        config,
        isQueryString,
        query,
    };
    return await AxiosApi('post', params, { callbackFn }, action);
};

export const AxiosPut = (action = {}, { query }) => async (url, data, config, { ...eles }) => {
    const { callbackFn, isBack, isQueryString, isInitial } = eles;
    data = query.preQueryString(data, { isBack, isQueryString, isInitial });
    const params = {
        url,
        data,
        config,
        isQueryString,
        query,
    };
    return await AxiosApi('put', params, { callbackFn }, action);
};

export const AxiosDelete = (action = {}, { query }) => async (url, data, config, { ...eles }) => {
    const { callbackFn, isBack, isQueryString, isInitial } = eles;
    data = query.preQueryString(data, { isBack, isQueryString, isInitial });
    const params = {
        url,
        data,
        config,
        isQueryString,
        query,
    };
    return await AxiosApi('delete', params, { callbackFn }, action);
};

// do axios

const AxiosApi = async (type, { url, data: configData, config, ...others }, { callbackFn }, action) => {
    const { showLoading = () => {} } = action;
    showLoading(true);
    let returnData;
    let apiResult;
    let isError = false;
    try {
        apiResult = await Axios[type](url, configData, config);
        const { data } = apiResult;
        if (data.success) {
            returnData = data.result;
            if (others.isQueryString) others.query.set(configData);
        } else if ('application/vnd.ms-excel' === apiResult['headers']['content-type']) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(apiResult['headers']['content-disposition']);
            let filename;
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            returnData = { data, filename };
        } else {
            let messageAry = [];
            messageAry.push(data.message, ...(data.result || []));
            alert(messageAry.join('\n'));
        }
    } catch (error) {
        const { response } = error;
        apiResult = error;
        isError = true;
        if (response) {
            if (response.status === 404) {
                alert('無此檔案目錄，請通知IT部門');
            } else if (response.status === 400) {
                alert('傳送參數有誤');
            } else if (response.status === 401) {
                // token過期
                alert('連線逾時，即將轉跳重新登入');
                localStorage.clear();
                window.location.href = '/';
            } else if (response.status === 500) {
                alert('程式異常，請通知IT部門');
            } else if (response.status === 405) {
                // 後台無此方法請求
                alert('API呼叫錯誤的方法，請通知IT部門');
            } else if (response.status === 499) {
                // 後台無此方法請求
                alert('使用不信任憑證存取網域，請使用HTTPS');
            }
        } else {
            console.error(error);
        }
    }
    showLoading(false);

    return isError ? returnData : _.isFunction(callbackFn) ? callbackFn(returnData, apiResult) : returnData;
};
