import React, { useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../redux/dispatch';

import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import useAxiosFunction from '../apis/useAxiosFn';
import { Button } from './';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

const HOCButtonGroup = ({ handleExcelUpload, options = [], children }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            <span ref={anchorRef} onClick={handleToggle}>
                {children}
            </span>
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'center top',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    {options.map(({ text, value }, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(event) => {
                                                handleExcelUpload(event, value);
                                                setOpen(false);
                                            }}
                                        >
                                            {text}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

const UpLoadFileButton = React.forwardRef(
    ({ url: propsUrl, actions, dispatch, children, options = [], queryKey = '', isAlert = false, ...props }, ref) => {
        const Ajax = useCallback(useAxiosFunction(actions), []);
        const accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const [uploadKey, setUploadKey] = useState(uuid());
        const uploadRef = useRef();
        const sendQuery = useRef();

        const handleExcelUpload = (e, value) => {
            sendQuery.current = value;
            uploadRef.current.click();
        };

        const handleUploadFile = (e) => {
            uploadApi();
        };

        const uploadApi = async () => {
            const files = uploadRef.current.files?.[0] ?? undefined;
            const url = sendQuery.current ? `${propsUrl}/${sendQuery.current}` : propsUrl;
            const confirm = isAlert && window.confirm;
            let source = new FormData();
            if (!files) return;
            if (confirm && !confirm(`確認要選 ${sendQuery.current} 月上傳嗎？`) && !setUploadKey(uuid())) return;
            if (files) source.append('file', files);
            await Ajax.post(
                url,
                source,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                },
                {
                    callbackFn: (result) => {
                        if (result) alert(`${files.name} 上傳成功`);
                    },
                }
            );
            setUploadKey(uuid());
        };

        const inputUploadProps = {
            ref: uploadRef,
            key: uploadKey,
            accept: accept,
            type: 'file',
            className: 'display-none',
            onChange: () => handleUploadFile(),
        };

        let btn;

        if (!_.isEmpty(options)) {
            btn = (
                <HOCButtonGroup handleExcelUpload={handleExcelUpload} options={options}>
                    <Button {...props}>
                        {children}
                        <input {...inputUploadProps} />
                    </Button>
                </HOCButtonGroup>
            );
        } else {
            btn = (
                <Button handleOnClick={handleExcelUpload} {...props}>
                    {children}
                    <input {...inputUploadProps} />
                </Button>
            );
        }
        return <React.Fragment>{btn}</React.Fragment>;
    }
);

export default connect(null, mapDispatchToProps('showLoading'))(UpLoadFileButton);
