import React, { useRef, useImperativeHandle } from 'react';
import { Card, TextInput, Select, AutocompleteInput } from '../../../components';
import useCommomFn from '../../../common/useCommomFn';

const CycleOrderExportCard = ({ defaultData = {}, options, ...props }, ref) => {
    const { name = '', deliveryCycle = '', shopID, platformType = '' } = defaultData;
    const { shopOptions, shipDaysOptions, platformTypeOptions } = options;
    const { getMatchKeysFn, getOptionMapFn } = useCommomFn();
    const shipmentCycleRef = useRef();
    const storeRef = useRef();
    const searchTextRef = useRef();
    const platformTypeRef = useRef();
    
    useImperativeHandle(ref, () => ({
        getResult: () => {
            const shipmentCycleValue = shipmentCycleRef.current.value;
            const storeValue = storeRef.current.value ? getMatchKeysFn(shopOptions, storeRef.current.value) : '';
            const searchTextValue = searchTextRef.current.value;
            const platformTypeValue = platformTypeRef.current.value;
            return {
                shopID: storeValue,
                deliveryCycle: shipmentCycleValue,
                name: searchTextValue,
                platformType: platformTypeValue,
            };
        },
    }));

    return (
        <Card>
            <div className="form-flex-row">
                <TextInput
                    label="特定搜尋"
                    inputRef={searchTextRef}
                    placeholder="會員編號、姓名、電話"
                    defaultValue={name}
                />
                <Select
                    label="出貨週期"
                    inputRef={shipmentCycleRef}
                    sourceData={shipDaysOptions}
                    noEmptyOption
                    defaultValue={deliveryCycle}
                />
                <AutocompleteInput
                    label="門店"
                    inputRef={storeRef}
                    sourceData={shopOptions}
                    defaultValue={getOptionMapFn(shopOptions, shopID, { view: {} })}
                />
            </div>
            <div className="form-flex-row">
                <Select
                    label="系統別"
                    inputRef={platformTypeRef}
                    sourceData={platformTypeOptions}
                    defaultValue={platformType}
                    noEmptyOption
                />
            </div>
        </Card>
    );
};

export default React.forwardRef(CycleOrderExportCard);
