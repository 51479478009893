import React from 'react';
import DialogModal from '../../components/dialog/DialogModal';
import { Button, TextInput, Select } from '../../components';
import _ from 'lodash';

const CategoriesEditDialog = ({ options, categoryId }) => {
    const { nodes, parentId } = options[categoryId];
    const stage = _.split(nodes, '-').length;
    let option = _.filter(_.values(options), (target) => _.split(target.nodes, '-').length === stage - 1);
    option = _.map(option, (target) => ({ ...target, text: target.name, value: target.id }));

    return (
        <React.Fragment>
            <DialogModal.Header>
                <h1>編輯分類</h1>
            </DialogModal.Header>
            <DialogModal.Body>
                <div className="category-dialog-content">
                    <Select width={300} label="父層分類" sourceData={option} noEmptyOption defaultValue={parentId} />
                    <TextInput width={300} label="名稱" defaultValue={options[categoryId]?.name || ''} />
                </div>
            </DialogModal.Body>
            <DialogModal.Footer>
                <div className="category-dialog-button">
                    <Button color="auxiliary_c" fullColor>
                        送出資料
                    </Button>
                </div>
            </DialogModal.Footer>
        </React.Fragment>
    );
};

export default CategoriesEditDialog;
