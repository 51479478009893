import * as React from 'react';
import { TextInput } from '.';
import { makeStyles, IconButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { useLocalStorage } from '../common/useStorage';
import _ from 'lodash';

const SearchInput = ({ label, width = 220, searchRef, handleOnClick, searchListLength = 10, ...props }) => {
    const clsses = makeStyles(() => ({
        textWidth: {
            width: width,
        },
    }))();

    const [options, setOptions] = useLocalStorage('search', []);
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const magnifier = (
        <InputAdornment position="start">
            <IconButton style={{ fontSize: 0 }} onClick={handleOnClick}>
                <SearchIcon />
            </IconButton>
        </InputAdornment>
    );

    return (
        <Autocomplete
            freeSolo
            className={clsses.textWidth}
            id="controllable-states-demo"
            value={value}
            clearOnEscape
            onChange={(event, newValue) => {
                setValue(newValue);
                if (!!_.toString(newValue))
                    setOptions((target) => _.slice(_.union([newValue, ...target]), 0, searchListLength));
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
                if (reason === 'clear') handleOnClick(event, newInputValue);
            }}
            options={options}
            {...props}
            renderInput={(params) => {
                params.InputProps.startAdornment = magnifier;
                return (
                    <TextInput
                        {...params}
                        label={label || ''}
                        width={width}
                        inputRef={searchRef || null}
                        placeholder={props.placeholder}
                    />
                );
            }}
        />
    );
};

export default SearchInput;
