import React, { useRef, useImperativeHandle } from 'react';
import { Card, TextInput, AutocompleteInput } from '../../../components';
import useCommomFn from '../../../common/useCommomFn';
import _ from 'lodash';

const StoreCoachCard = ({ sourceData, disabled, optionData, ...props }, ref) => {
    const { ownedShopID, salesShopID, coachName } = sourceData;
    const { shopOption } = optionData;
    const { getOptionMapFn, getMatchKeysFn } = useCommomFn();
    const locationStoreRef = useRef();
    const sellStoreRef = useRef();
    const coachRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const locationStore = locationStoreRef.current.value;
            const sellStore = sellStoreRef.current.value;
            const coach = coachRef.current.value;
            const storeShopVal = !!locationStore ? getMatchKeysFn(shopOption, locationStore) : '';
            const sellStroeVal = !!sellStore ? getMatchKeysFn(shopOption, sellStore) : '';
            return {
                ownedShopID: storeShopVal,
                salesShopID: sellStroeVal,
                coachName: coach,
            };
        },
    }));

    return (
        <Card>
            <div>
                <h4 className="card-title">門店教練資訊</h4>
            </div>
            <div className="form-flex-row">
                {!_.isEmpty(shopOption) && (
                    <React.Fragment>
                        <AutocompleteInput
                            label="所屬店鋪"
                            name="locationStore"
                            inputRef={locationStoreRef}
                            sourceData={shopOption}
                            defaultValue={getOptionMapFn(shopOption, ownedShopID, { view: {} })}
                            disabled={disabled}
                        />
                        <AutocompleteInput
                            label="販售店鋪"
                            name="sellStore"
                            inputRef={sellStoreRef}
                            sourceData={shopOption}
                            defaultValue={getOptionMapFn(shopOption, salesShopID, { view: {} })}
                            disabled={disabled}
                        />
                    </React.Fragment>
                )}
                <TextInput
                    label="教練(可選填)"
                    name="coach"
                    inputRef={coachRef}
                    defaultValue={coachName}
                    disabled={disabled}
                />
            </div>
        </Card>
    );
};

export default React.forwardRef(StoreCoachCard);
