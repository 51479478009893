import { useCallback } from 'react';
import {
    optionMapFn,
    cardNumberFormatFn,
    validDateFormatFn,
    padStartFn,
    padEndFn,
    resultFn,
    intersectionFn,
    matchSorterSingleKeysFn,
    routesMapComponentFn,
    menuRoutesFn,
} from './common';
// import { querySearhFn } from './queryString';

const useToolFunction = () => {
    const getOptionMapFn = useCallback(optionMapFn, []);
    const getCardNumberFormatFn = useCallback(cardNumberFormatFn, []);
    const getValidDateFormatFn = useCallback(validDateFormatFn, []);
    const getPadStartFn = useCallback(padStartFn, []);
    const getPadEndFn = useCallback(padEndFn, []);
    const getResultFn = useCallback(resultFn, []);
    const getIntersectionFn = useCallback(intersectionFn, []);
    const getMatchKeysFn = useCallback(matchSorterSingleKeysFn, []);
    const getRoutesMapComponentFn = useCallback(routesMapComponentFn, []);
    const getMenuRoutesFn = useCallback(menuRoutesFn, []);
    return {
        getOptionMapFn,
        getCardNumberFormatFn,
        getValidDateFormatFn,
        getPadStartFn,
        getPadEndFn,
        getResultFn,
        getIntersectionFn,
        getMatchKeysFn,
        getRoutesMapComponentFn,
        getMenuRoutesFn,
    };
};

export default useToolFunction;
