import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps } from '../../../redux/dispatch';

import { Tabs, Tab, TableBody, TableRow, TableCell, IconButton, Collapse } from '@material-ui/core';
import {
    KeyboardArrowUp,
    KeyboardArrowDown,
    CheckCircleOutline,
    AddCircle,
    Edit,
    DeleteForever,
} from '@material-ui/icons';
import Tables from '../../components/Tables';
import { Card } from '../../components';
import CategoriesAddDialog from './CategoriesAddDialog';
import CategoriesEditDialog from './CategoriesEditDialog';
import CategoriesDeleteDialog from './CategoriesDeleteDialog';

import { v4 as uuid } from 'uuid';
import _ from 'lodash';

const CategoriesLabel = [
    {
        labelKey: 'arrow',
        label: '',
        minWidth: 20,
        format: (value, selfData, { open, setOpen }) => {
            return (
                selfData.children && (
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                )
            );
        },
    },
    {
        labelKey: 'name',
        label: '名稱',
        minWidth: 220,
    },
    {
        labelKey: 'allen',
        label: '擁有子分類',
        minWidth: 100,
        align: 'center',
        format: (value, selfData) => {
            return selfData.children && <CheckCircleOutline className="category-check-icon" />;
        },
    },
    {
        labelKey: '',
        label: ({ handleAddCategories }) => {
            return (
                <IconButton onClick={handleAddCategories}>
                    <AddCircle className="add-icon" tooltip="新增子分類" />
                </IconButton>
            );
        },
        minWidth: 150,
        align: 'right',
        format: (value, selfData, { handleAddCategories, handleEditClick, handleDeleteClick }) => (
            <div className="category-buttons flex">
                <IconButton onClick={handleAddCategories(selfData.id)}>
                    <AddCircle className="add-icon" tooltip="新增子分類" />
                </IconButton>
                <IconButton onClick={handleEditClick(selfData.id)}>
                    <Edit className="edit-icon" tooltip="編輯" />
                </IconButton>
                <IconButton onClick={handleDeleteClick(selfData.id)}>
                    <DeleteForever className="delete-icon" tooltip="刪除" />
                </IconButton>
            </div>
        ),
    },
];

// 找尋對應階層 stage : number , level : number
const categoryStageFn = (target, stage, level) => {
    let source = [];
    _.forOwn(target, (value, key) => {
        const nodesAry = _.split(key, '-');
        if (level) {
            if (level.toString() === nodesAry[0].toString() && nodesAry.length === stage) source.push(value);
        } else if (nodesAry.length === stage) source.push(value);
    });
    return source;
};

// 第二層
const CustomizationRow = ({ defaultData, headerLabel, orignData, ...props }) => {
    const childrenAry = defaultData.children || [];
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <TableRow hover tabIndex={-1} className="category-table-row">
                {headerLabel.map((target) => {
                    const value =
                        typeof defaultData[target.labelKey] === 'object'
                            ? target.labelKey
                            : defaultData[target.labelKey] || '';
                    const { align = 'left' } = target;
                    let property = {
                        align,
                        key: uuid(),
                    };
                    return (
                        <TableCell {...property}>
                            {_.isFunction(target.format)
                                ? target.format(value, defaultData, { ...props, open, setOpen, orignData })
                                : value}
                        </TableCell>
                    );
                })}
            </TableRow>
            {!_.isEmpty(childrenAry) && (
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={headerLabel.length}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <div className="category-sub-table">
                                <SubTableRow defaultData={childrenAry} orignData={orignData} {...props} />
                            </div>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

// 第三層
const SubTableRow = ({ defaultData, orignData, ...props }) => {
    return (
        <Tables size="small">
            <TableBody>
                {_.map(defaultData, (target) => {
                    const source = orignData[target];
                    return (
                        <TableRow key={uuid()} hover tabIndex={-1}>
                            <TableCell width={420}>{source.name}</TableCell>
                            <TableCell align="right">
                                <div className="category-buttons flex">
                                    <IconButton onClick={props.handleEditClick(source.id)}>
                                        <Edit className="edit-icon" tooltip="編輯" />
                                    </IconButton>
                                    <IconButton onClick={props.handleDeleteClick(source.id)}>
                                        <DeleteForever className="delete-icon" tooltip="刪除" />
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Tables>
    );
};

const CategoriesTable = ({ defaultData = [], actions, ...props }) => {
    const [sourceData, setSourceData] = useState({});
    const [orignData, setOrignData] = useState([]);
    const [tabsIndex, setTabsIndex] = useState(0);
    const [isMount, setIsMount] = useState(false);
    const { dialogModal } = actions;

    const handleTabsOnChange = (e, value) => {
        setTabsIndex(value);
    };

    const handleAddCategories = (categoryId) => (e) => {
        const defulatProps = { categoryId, options: orignData };
        const Render = <CategoriesAddDialog {...defulatProps} />;
        dialogModal({
            isOpen: true,
            backdropClose: true,
            className: 'info-dialog',
            Render,
        });
    };

    const handleEditClick = (categoryId) => (e) => {
        const defulatProps = { categoryId, options: orignData };
        const Render = <CategoriesEditDialog {...defulatProps} />;
        dialogModal({
            isOpen: true,
            backdropClose: true,
            className: 'info-dialog',
            Render,
        });
    };

    const handleDeleteClick = (categoryId) => (e) => {
        const defulatProps = { categoryId, options: orignData };
        const Render = <CategoriesDeleteDialog {...defulatProps} />;
        dialogModal({
            isOpen: true,
            backdropClose: true,
            className: 'warn-dialog',
            Render,
        });
    };

    useEffect(() => {
        const nodeKeyby = _.keyBy(defaultData, (o) => o.nodes);
        const idKeyby = _.keyBy(defaultData, (o) => o.id);
        setSourceData(nodeKeyby);
        setOrignData(idKeyby);
        setIsMount(true);
        return () => setIsMount(false);
    }, [defaultData]);

    useEffect(() => {
        if (!_.isEmpty(sourceData)) {
            const initItem = categoryStageFn(sourceData, 1);
            if (!tabsIndex && !sourceData[tabsIndex]) setTabsIndex(initItem[0].id);
        }
    }, [tabsIndex, sourceData]);

    return (
        <React.Fragment>
            {isMount && (
                <Card className="category-content" noWidth>
                    <div className="category-tabs">
                        <IconButton className="category-tabs-add" onClick={handleAddCategories(0)}>
                            <AddCircle className="add-icon" />
                        </IconButton>
                        {!_.isEmpty(categoryStageFn(sourceData, 1)) && tabsIndex && (
                            <Tabs
                                value={tabsIndex}
                                onChange={handleTabsOnChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                            >
                                {_.map(categoryStageFn(sourceData, 1), (target) => (
                                    <Tab key={target.name} label={target.name} value={target.id} />
                                ))}
                            </Tabs>
                        )}
                    </div>
                    <Tables>
                        <Tables.TableRowHeader
                            headerLabel={CategoriesLabel}
                            handleAddCategories={handleAddCategories(tabsIndex)}
                        />
                        <TableBody>
                            {_.map(categoryStageFn(sourceData, 2, tabsIndex), (target) => {
                                return (
                                    <CustomizationRow
                                        key={uuid()}
                                        headerLabel={CategoriesLabel}
                                        defaultData={target}
                                        sourceData={sourceData}
                                        orignData={orignData}
                                        handleAddCategories={handleAddCategories}
                                        handleEditClick={handleEditClick}
                                        handleDeleteClick={handleDeleteClick}
                                    />
                                );
                            })}
                        </TableBody>
                    </Tables>
                </Card>
            )}
        </React.Fragment>
    );
};

export default connect(null, mapDispatchToProps('dialogModal'))(CategoriesTable);
