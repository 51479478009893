import React, { useState, useRef, useImperativeHandle, useCallback, useEffect } from 'react';
// @material-ui
import { Card, TextInput, Button, Select, DatePicker } from '../../../components';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Axios from 'axios';

const ReasonCard = React.forwardRef(({ unsubscribeMap }, ref) => {
    const reasonRef = useRef();
    const returnDateRef = useRef();
    const supplementRef = useRef();

    useImperativeHandle(ref, () => ({
        getResult: () => {
            const reason = reasonRef.current.value;
            const returnDate = returnDateRef.current.value;
            const supplement = supplementRef.current.value;
            return {
                unsubscribeCode: reason,
                unsubscribeTime: returnDate,
                unsubscribeExtraInstruction: supplement,
            };
        },
    }));

    return (
        <Card className="title-color-root title-color-pseudo-secondary-bg child-X-Gutters-2">
            <div className="title-color-label">退訂(選填)</div>
            <div>
                <div className="form-flex-row">
                    <Select label="退訂原因" inputRef={reasonRef} sourceData={unsubscribeMap} />
                    <DatePicker label="退訂日期" inputRef={returnDateRef} format="yyyy/MM" disablePast/>
                </div>
                <div className="form-flex-row">
                    <TextInput label="退訂原因補充說明" fullWidth inputRef={supplementRef} />
                </div>
            </div>
        </Card>
    );
});

const UnsubscribeCard = ({ cycleOrderID, setPresetOrderStatus, sourceData, tmpSourceData }) => {
    const { productsType } = sourceData;
    const { products } = tmpSourceData;
    const history = useHistory();
    const groupRef = useRef({});
    const [unsubscribeMap, setUnsubscribeMap] = useState([]);
    const [isMount, setIsMount] = useState(false);
    
    const initAllData = useCallback(async () => {
        await Axios.get('/api/cycleorderoptions/unsubscribe').then((res) => {
            const { data } = res;
            if (data.success) {
                setUnsubscribeMap(data.result);
                setIsMount(true);
            }
        });
    }, []);

    const doApi = async (params) => {
        await Axios.put(`/api/cycleorders/unsubscribe/${cycleOrderID}`, params).then((res) => {
            const { data } = res;
            if (data.success) {
                alert(data.message);
                history.push('/order/cycleorderlist');
            }
        });
    };

    const handleOnClick = (e) => {
        const source = groupRef.current[0].getResult();
        let newAry = _.union(products.map(({ productType }) => (productType)));
        newAry = _.map(newAry , ( item => ({
            unsubscribeType: item,
            ...source
        })))
        doApi(newAry);
    };

    const handleCancel = (e) => {
        setPresetOrderStatus('');
    };

    useEffect(() => {
        initAllData();
    }, [initAllData]);

    return (
        <div className="view-normal-width container-root container-disable-gutters">
            {isMount && (
                <React.Fragment>
                    {!_.isEmpty(productsType) &&
                        productsType.map(({ productName }, id) => (
                            <ReasonCard
                                unsubscribeMap={unsubscribeMap}
                                productName={productName}
                                ref={(el) => (groupRef.current[id] = el)}
                                key={uuid()}
                            />
                        ))}

                    <div className="flex flex-jc-center child-Y-Gutters-3 button-root">
                        <Button label={`取消`} color="secondary" handleOnClick={handleCancel} />
                        <Button label={`送出退訂`} fullColor color="secondary" handleOnClick={handleOnClick} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default UnsubscribeCard;
