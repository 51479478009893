import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import _ from 'lodash';

const viewMap = {
    'yyyy/MM/dd': ['year', 'month', 'date'],
    'yyyy/MM': ['year', 'month'],
    'yyyy': ['year'],
};

const MaterialUIPickers = ({
    label,
    format = 'yyyy/MM/dd',
    defaultValue: defulatvalue,
    disableFuture,
    disablePast,
    inputRef,
    handleDateChange: propsHandleDateChange,
    ...props
}) => {
    const [selectedDate, setSelectedDate] = React.useState(defulatvalue || null);

    const handleDateChange = (date) => {
        propsHandleDateChange && propsHandleDateChange(date);
        setSelectedDate(date);
    };

    const defaultProps = {
        autoOk: true,
        clearable: true,
        margin: 'normal',
        onChange: handleDateChange,
        defulatvalue,
        inputRef,
        format,
        value: selectedDate,
        label,
        disableFuture,
        disablePast,
        views: viewMap[format] || viewMap['yyyy/MM/dd'],
        InputLabelProps: { shrink: true },
        helperText: '',
        ...props,
    };

    if (selectedDate instanceof Date) {
        let text = '';
        _.isNaN(selectedDate.getTime()) ? (text = '無效日期') : (text = '');
        defaultProps.helperText = text;
    } else if (!selectedDate) defaultProps.helperText = '請選擇日期';

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker {...defaultProps} InputAdornmentProps={{ position: 'end' }} />
        </MuiPickersUtilsProvider>
    );
};

export default MaterialUIPickers;
