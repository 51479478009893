import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Backdrop from './Backdrop';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// action
import { setDialogData } from '../../../redux/dialog/action';
// select
import { getDialogData } from '../../../redux/dialog/selector';

const DELAY_TIME = 300;

const DialogModals = ({ open = false, actions, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { dialogData } = props;
    
    const defaultProps = {
        isOpen: dialogData.isOpen,
        onAfterOpen: () => setIsOpen(true),
        onRequestClose: () => {
            actions.setDialogData({ isOpen: false });
            setIsOpen(false);
        },
        shouldCloseOnOverlayClick: !!dialogData.backdropClose,
        closeTimeoutMS: DELAY_TIME,
    };

    useEffect(() => {
        !dialogData.isOpen && setIsOpen(false);
    }, [dialogData.isOpen]);

    return (
        <Backdrop {...defaultProps}>
            <CSSTransition in={isOpen} timeout={DELAY_TIME} classNames={dialogData.className || "info-dialog"} unmountOnExit>
                <div className="dialog-box">{dialogData.Render}</div>
            </CSSTransition>
        </Backdrop>
    );
};

DialogModals.Header = ({ children, ...props }) => {
    return <div className="dialog-box-header">{children}</div>;
};

DialogModals.Body = ({ children, ...props }) => {
    return <div className="dialog-box-body">{children}</div>;
};

DialogModals.Footer = ({ children, ...props }) => {
    return <div className="dialog-box-footer">{children}</div>;
};

const mapStateToProps = (state) => {
    return {
        dialogData: getDialogData(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setDialogData,
    };
    return {
        actions: bindActionCreators(actions, dispatch),
        dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogModals);
