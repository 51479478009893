import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Card, TextInput, Button, DatePicker, Select } from '../../../components';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';

// title-color-root
const SuspendCard = ({ cycleOrderID, setPresetOrderStatus }) => {
    const [isMount, setIsMount] = useState(false);
    const [reasonMap, setReasonMap] = useState([]);
    const history = useHistory();
    const reasonRef = useRef();
    const startDateRef = useRef();
    const endDateRef = useRef();
    const supplementRef = useRef();

    const getResult = () => {
        const reason = reasonRef.current.value;
        const startDate = startDateRef.current.value;
        const endDate = endDateRef.current.value;
        const supplement = supplementRef.current.value;
        return {
            suspensionCode: reason,
            shippingSuspensionStartDate: startDate,
            shippingSuspensionEndDate: endDate,
            suspensionExtraInstruction: supplement,
        };
    };

    const doApi = async (params) => {
        await Axios.put(`/api/cycleorders/suspension/${cycleOrderID}`, { ...params }).then((res) => {
            const { data } = res;
            if (data.success) {
                alert(data.message);
                history.push('/order/cycleorderlist');
            }
        });
    };

    const initAllData = useCallback(async () => {
        await Axios.get('/api/cycleorderoptions/suspension').then((res) => {
            const { data } = res;
            if (data.success) {
                setReasonMap(data.result)
                setIsMount(true);
            }
        });
    }, []);

    const handleOnClick = (e) => {
        doApi(getResult());
    };

    const handleCancel = (e) => {
        setPresetOrderStatus('');
    };

    useEffect(() => {
        initAllData();
    }, [initAllData]);

    return (
        <div className="view-normal-width container-root container-disable-gutters">
            {isMount && (
                <React.Fragment>
                    <Card className="title-color-root title-color-pseudo-primary-bg child-X-Gutters-2">
                        <div className="title-color-label">暫停出貨</div>
                        <div>
                            <div className="form-flex-row">
                                <Select label="暫停原因" inputRef={reasonRef} sourceData={reasonMap} />
                                <DatePicker label="暫停出貨月份(起始)" inputRef={startDateRef} format="yyyy/MM" disablePast/>
                                <DatePicker label="暫停出貨月份(截止)" inputRef={endDateRef} format="yyyy/MM" disablePast/>
                            </div>
                            <div className="form-flex-row">
                                <TextInput
                                    label="暫停原因補充說明"
                                    name="supplement"
                                    inputRef={supplementRef}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </Card>
                    <div className="flex flex-jc-center child-Y-Gutters-3 button-root">
                        <Button label={`取消`} handleOnClick={handleCancel} />
                        <Button label={`送出暫停`} fullColor handleOnClick={handleOnClick} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default SuspendCard;
