import React, { useState, useEffect, useImperativeHandle, useMemo } from 'react';
// material ui
import { TableRow, TableCell } from '@material-ui/core';

import plus from '../../../../images/plus.svg';
import minus from '../../../../images/minus.svg';
import Tables from '../../../components/Tables';
import { Select } from '../../../components';

import { v4 as uuid } from 'uuid';
import _ from 'lodash';


const formTableHeader = [
    {
        labelKey: 'productVariantName',
        label: '商品項目',
        minWidth: '200',
        align: 'center',
        format: (curVal, selfData, sourceParams) => {
            let target = curVal;
            if (selfData['type'] === 'add') {
                const { productItem, handleOnChange, sourceData: oriData = [] } = sourceParams;
                const oriId = oriData.map(({ value }) => value);
                const select = _.filter(productItem, ({ value }) => !oriId.includes(value) || value === selfData.value);
                const options = select.map((i) => ({ ...i, value: i.value, text: i.text }));
                target = (
                    <Select
                        sourceData={options}
                        width={150}
                        handleOnChange={handleOnChange(selfData)}
                        defaultValue={selfData['value']}
                        noEmptyOption
                    />
                );
            }
            return target;
        },
    },
    {
        labelKey: 'price',
        label: '', //價格
        minWidth: '250',
        align: 'right',
        format: (value, selfData, sourceParams) => {
            const price = value * 1;
            return (
                <span className="price-span third-color-normall">
                    $<em>{price.toLocaleString('en-US')}</em>
                </span>
            );
        },
    },
    {
        labelKey: 'sign',
        label: '',
        minWidth: '10',
        align: 'center',
        format: (value, selfData, sourceParams) => 'x',
    },
    {
        labelKey: 'amount',
        label: '盒數',
        minWidth: '150',
        align: 'center',
        format: (value, selfData, sourceParams) => {
            const { handleAddClick, handleReduceClick, isReadOnly } = sourceParams;
            return (
                <React.Fragment>
                    <div className="div-box-counts">
                        {!isReadOnly && (
                            <img className="cursor-pointer" src={plus} alt="plus" onClick={handleAddClick(selfData)} />
                        )}
                        <span>{value || 0}</span>
                        {!isReadOnly && (
                            <img
                                className="cursor-pointer"
                                src={minus}
                                alt="minus"
                                onClick={handleReduceClick(selfData)}
                            />
                        )}
                    </div>
                </React.Fragment>
            );
        },
    },
    {
        labelKey: 'totalPrice',
        label: '訂購金額',
        minWidth: '150',
        align: 'right',
        format: (value, selfData, sourceParams) => {
            const totalPrice = selfData['amount'] * 1 * selfData['price'];
            return (
                <span className="price-span third-color-normall">
                    $<em>{totalPrice.toLocaleString('en-US')}</em>
                </span>
            );
        },
    },
    {
        labelKey: 'deleteButton',
        label: '',
        minWidth: '80',
        align: 'center',
        format: (value, selfData, sourceParams) => {
            const { handleDelteClick, isReadOnly } = sourceParams;
            return (
                <React.Fragment>
                    {!isReadOnly && (
                        <span className="third-color-light cursor-pointer" onClick={handleDelteClick(selfData)}>
                            刪除
                        </span>
                    )}
                </React.Fragment>
            );
        },
    },
];

const TableRowTotalPrice = ({ targetDataList }) => {
    const totalMoney = targetDataList.reduce((acc, cur) => {
        let tar = acc;
        tar = tar + cur.price * cur.amount;
        return tar;
    }, 0);
    return (
        <React.Fragment>
            <TableRow hover tabIndex={-1}>
                <TableCell colSpan={3} />
                <TableCell align="center">總金額</TableCell>
                <TableCell align="right">
                    <span className="price-span third-color-normall">
                        $<em>{totalMoney.toLocaleString('en-US')}</em>
                    </span>
                </TableCell>
                <TableCell />
            </TableRow>
        </React.Fragment>
    );
};

const ProductsTable = ({ sourceData = {}, productItem = [], disabled, ...props }, ref) => {
    const [TableDatas, setTableDatas] = useState([]);
    const useTableRowHeader = useMemo(() => <Tables.TableRowHeader headerLabel={formTableHeader} />, []);

    useEffect(() => {
        setTableDatas([
            ...sourceData.map((t) => ({ ...t, uuid: uuid(), text: t.productVariantName, value: t.productVariantID })),
        ]);
    }, [sourceData]);

    useImperativeHandle(ref, () => ({
        // 觸發新增商品
        setData: () => {
            const newState = [];
            const newSelectProductItem = _.xorBy(productItem, TableDatas, 'value');
            if (newSelectProductItem.length === 0) return;
            const curProduct = newSelectProductItem[0];
            const addProduct = {
                ...curProduct,
                cycleOrderItemID: 0,
                productVariantName: curProduct.text,
                productVariantID: curProduct.value,
                amount: 1,
                type: 'add',
                uuid: uuid(),
            };
            newState.push(...TableDatas, addProduct);
            setTableDatas(newState);
        },

        getResult: () => {
            return TableDatas.map(({ amount, cycleOrderItemID, productVariantName, productVariantID, price }) => ({
                amount,
                cycleOrderItemID,
                productVariantName,
                productVariantID,
                price,
            }));
        },
    }));

    const handleOnChange = (target) => (val, e) => {
        const selectedProd = _.find(productItem, (i) => i.value === val); //選擇的商品
        target.value = selectedProd.value;
        target.text = selectedProd.text;
        target.price = selectedProd.price;
        target.productVariantName = selectedProd.text;
        target.productVariantID = selectedProd.value;
        target.amount = 1;
        setTableDatas([...TableDatas]);
    };

    const handleDelteClick = (target) => (e) => {
        if (disabled) return;
        const source = _.filter(TableDatas, (i) => i.uuid !== target.uuid);
        setTableDatas([...source]);
    };

    const handleAddClick = (target) => (e) => {
        if (disabled) return;
        handleCalcCounts(target, 'add');
    };

    const handleReduceClick = (target) => (e) => {
        if (disabled) return;
        handleCalcCounts(target, 'reduce');
    };

    const handleCalcCounts = (target, type) => {
        const { value } = target;
        if (!value) return;
        if (type === 'add') {
            target.amount++;
        } else {
            if (target.amount === 1) return;
            target.amount--;
        }
        setTableDatas([...TableDatas]);
    };
    return (
        <Tables>
            {useTableRowHeader}
            <Tables.TableRowBody
                headerLabel={formTableHeader}
                sourceData={TableDatas}
                productItem={productItem}
                handleOnChange={handleOnChange}
                handleAddClick={handleAddClick}
                handleReduceClick={handleReduceClick}
                handleDelteClick={handleDelteClick}
                isReadOnly={disabled}
            >
                <TableRowTotalPrice targetDataList={TableDatas} />
            </Tables.TableRowBody>
        </Tables>
    );
};

export default React.forwardRef(ProductsTable);
