import { bindActionCreators } from 'redux';
import { setLoadingData } from './loading/action';
import { setDialogData } from './dialog/action';

const sourcePropsMap = {
    showLoading: setLoadingData,
    dialogModal: setDialogData,
};

const mapDispatchToProps = (...sourceProps) => (dispatch) => {
    let sourceActions = {};
    sourceProps.forEach((target) => {
        sourcePropsMap[target] && (sourceActions[target] = sourcePropsMap[target]);
    });

    const actions = {
        ...sourceActions,
    };
    return {
        actions: bindActionCreators(actions, dispatch),
        dispatch,
    };
};

export { mapDispatchToProps };
