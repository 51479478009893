import React, { useImperativeHandle, useRef } from 'react';
import { SearchInput, Button, UpLoadFileButton } from '../../components';

const ProductSearchBox = React.forwardRef(({ handleChangePage }, ref) => {
    const searchRef = useRef('');
    // do submit after newValue is undefined
    const handleSearchInput = (e, newValue) => {
        e.preventDefault();
        if (newValue === undefined) newValue = searchRef.current.value;
        handleChangePage && handleChangePage(e, 1, newValue);
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            return searchRef.current.value;
        },
    }));

    return (
        <div>
            <div className="flex flex-ai-center flex-jc-space-between">
                <form onSubmit={handleSearchInput} style={{ width: `100%` }}>
                    <SearchInput
                        width={`80%`}
                        style={{ justifyContent: 'flex-end' }}
                        placeholder="查詢會員編號．姓名電話．訂單編號"
                        handleOnClick={handleSearchInput}
                        searchRef={searchRef}
                    />
                </form>
                <div className="flex flex-column-gap-1">
                    <Button label={'匯出'} color={`auxiliary_b`} height={40} fullColor />
                    <UpLoadFileButton label={'匯入'} height={40} color={`auxiliary_a`} fullColor />
                </div>
            </div>
        </div>
    );
});

export default ProductSearchBox;
