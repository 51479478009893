import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormControl, InputLabel, Input } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const maskMap = {
    idnumber: [/[a-zA-Z]/, ...[...Array(9)].map(() => /[\d]/)],
    creditcard: [...Array(19)].map((key, idx) => (idx % 5 === 4 ? '-' : /[\d|*]/)),
    validdate: [/\d/, /\d/, ' ', '月', ' ', '/', ' ', /\d/, /\d/, ' ', '年'],
    default: [...Array(20)].map(() => /.*/),
    phone: [...Array(10)].map(() => /[\d]/),
    tel: [...Array(12)].map(() => /[\d]/),
    postalcode: [...Array(3)].map(() => /[\d]/),
    atmnumber: [...Array(5)].map(() => /[\d]/),
};

const TextMaskCustom = (formattedMask) => (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            mask={formattedMask}
            placeholderChar={'\u2000'}
            showMask
            keepCharPositions
        />
    );
};

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

const FormattedInputs = ({
    label = '',
    name,
    inputRef,
    width = 220,
    defaultValue,
    disabled,
    formatted = 'default',
}) => {
    const clsses = makeStyles(() => ({
        textWidth: {
            width,
        },
    }))();

    const handleOnChange = (event) => {};
    return (
        <FormControl margin="normal" className={clsses.textWidth}>
            <InputLabel shrink>{label}</InputLabel>
            <Input
                onChange={handleOnChange}
                name={name}
                inputRef={inputRef}
                defaultValue={defaultValue}
                disabled={disabled}
                inputComponent={TextMaskCustom(maskMap[formatted] || maskMap['default'])}
            />
        </FormControl>
    );
};

export default FormattedInputs;
