import { useCallback } from 'react';
import useQuery from '../common/useQuery';
import { AxiosGet, AxiosPost, AxiosPut, AxiosDelete } from './apiModuleBase';

const useAxiosFunction = (action) => {
    const query = useQuery();
    const getAction = useCallback(AxiosGet(action, { query }), []);
    const postAction = useCallback(AxiosPost(action, { query }), []);
    const putAction = useCallback(AxiosPut(action, { query }), []);
    const deleteAction = useCallback(AxiosDelete(action, { query }), []);
    return { get: getAction, post: postAction, put: putAction, delete: deleteAction };
};

export default useAxiosFunction;
